import React from 'react'
import PropTypes from 'prop-types'
import { replace, length } from 'ramda'
import { Controller } from 'react-hook-form'
import Input from './Input'

function withoutSpaceParse (value) {
  if (!value) return ''
  return replace(/ /g, '', value)
}

export function phoneNumberParse (value) {
  /* eslint-disable max-len */
  if (!value) return ''

  const withoutSpaces = withoutSpaceParse(value)
  const onlyNumsAndSymbol = replace(/[^\d\W]/g, '', withoutSpaces)

  if (length(onlyNumsAndSymbol) <= 4) {
    return `${onlyNumsAndSymbol.slice(0, 4)} `
  } else if (length(onlyNumsAndSymbol) <= 6) {
    return `${onlyNumsAndSymbol.slice(0, 4)} ${onlyNumsAndSymbol.slice(4, 6)}`
  } else if (length(onlyNumsAndSymbol) <= 9) {
    return `${onlyNumsAndSymbol.slice(0, 4)} ${onlyNumsAndSymbol.slice(4, 6)} ${onlyNumsAndSymbol.slice(6, 9)}`
  } else if (length(onlyNumsAndSymbol) <= 11) {
    return `${onlyNumsAndSymbol.slice(0, 4)} ${onlyNumsAndSymbol.slice(4, 6)} ${onlyNumsAndSymbol.slice(6, 9)} ${onlyNumsAndSymbol.slice(9, 11)}`
  } else if (length(onlyNumsAndSymbol) <= 13 || length(onlyNumsAndSymbol) > 13) {
    return `${onlyNumsAndSymbol.slice(0, 4)} ${onlyNumsAndSymbol.slice(4, 6)} ${onlyNumsAndSymbol.slice(6, 9)} ${onlyNumsAndSymbol.slice(9, 11)} ${onlyNumsAndSymbol.slice(11, 13)}`
  }
}

const PhoneInput = props => {
  const {
    name,
    control,
    rules,
    ...restProps
  } = props

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={''}
      rules={{
        ...rules,
        minLength: {
          value: 17,
          message: 'Номер телефона указан не полностью'
        }
      }}
      render={controller => (
        <Input
          {...controller}
          {...restProps}
          onChange={event => {
            const phoneFormat = phoneNumberParse(event.target.value)
            return controller.onChange(phoneFormat)
          }}
        />
      )}
    />
  )
}

PhoneInput.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object
}

export default PhoneInput
