import styled from 'styled-components'

export default styled.td`
  border-width: ${props => props.theme.shape.table.borderWidth};
  border-style: solid;
  border-color: ${props => props.theme.palette.accent.default};
  padding: ${props => props.padding || '15px'};
  text-align: justify;
  vertical-align: baseline;
`
