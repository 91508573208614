import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const SFlex = styled.div`
  align-items: ${props => props.align};
  display: flex;
  justify-content: ${props => props.justify};
`

const Flex = props => {
  return (
    <SFlex {...props} />
  )
}

Flex.propTypes = {
  align: PropTypes.string,
  justify: PropTypes.string
}

export default Flex
