import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FieldLabel } from '../Utils'

const Wrap = styled.div`
  position: relative;
`

const StyledTextArea = styled.textarea`
  background: ${props => props.theme.palette.input.background};
  border-radius: ${props => props.theme.shape.borderRadius};
  border: 1px solid transparent;
  color: inherit;
  display: block;
  font-size: inherit;
  font-family: inherit;
  line-height: 1.25;
  outline: none;
  padding: ${props => props.theme.shape.textarea.padding};
  min-height: ${props => props.theme.shape.textarea.minHeight};
  resize: none;
  transition: ${props => props.theme.transition};
  width: 100%;

  ::placeholder {
    color: ${props => props.theme.palette.input.placeholder};
  }

  ::-ms-input-placeholder {
    color: ${props => props.theme.palette.input.placeholder};
  }

  :hover {
    background: ${props => props.theme.palette.input.backgroundHover};
  }

  :focus {
    background: ${props => props.theme.palette.common.white};
    border-color: ${props => props.theme.palette.primary.default};
  }
`

const TextArea = props => {
  const {
    label,
    ...restProps
  } = props

  return (
    <Wrap>
      <FieldLabel>{label}</FieldLabel>
      <StyledTextArea {...restProps} />
    </Wrap>
  )
}

TextArea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string
}

export default TextArea
