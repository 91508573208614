import cookieCutter from 'cookie-cutter'
import { DEFAULT_LOCALE } from 'constants/locales'

const LOCALE = 'locale'

export const getLocale = () => {
  return cookieCutter.get(LOCALE) || DEFAULT_LOCALE
}

export const setLocale = locale => {
  cookieCutter.set(LOCALE, locale, {
    path: '/'
  })
}
