import styled, { css } from 'styled-components'
import CheckMark from './CheckMark'

export default styled.label`
  cursor: pointer;
  font-size: inherit;
  display: block;
  line-height: ${props => props.theme.shape.checkbox.size};
  margin-bottom: 15px;
  padding-left: calc(${props => props.theme.shape.checkbox.size} + 12px);
  position: relative;
  user-select: none;
  width: fit-content;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover ${CheckMark} {
    border-color: ${props => props.theme.palette.primary.default};
  }
  ${props => props.disabled && (
    css`
      opacity: 0.6;
      pointer-events: none;
    `
  )}
`
