import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const LoaderWrap = styled.div`
  width: ${({ width }) => width || '540px'};
  padding: ${({ padding }) => padding || '100px 0'};
  margin: 0 auto;
  font-size: 1.25em;
  text-align: center;
`

const Loader = ({ text }) => {
  return (
    <LoaderWrap>
      {text}
    </LoaderWrap>
  )
}

Loader.propTypes = {
  text: PropTypes.string.isRequired
}

export default Loader
