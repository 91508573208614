import React, { forwardRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import ReactSelect from 'react-select'

import FieldLabel from '../Utils/FieldLabel'
import FieldError from '../Utils/FieldError'
import getSelectStyles from './getSelectStyles'

import { useTranslate } from 'utils/translate'

const options = [
  // { value: 'cherry', label: 'Cherry' },
  // { value: 'banana', label: 'Banana' },
  // { value: 'apple', label: 'Apple' }
]

const SelectWrapper = styled('div')`
  
`

const Select = forwardRef((props, ref) => {
  const {
    theme,
    label,
    placeholder,
    error,
    onChange,
    type,
    onMenuOpen,
    onMenuClose,
    height,
    isLoading,
    isCreatable,
    onCreate,
    ...restProps
  } = props

  const { t } = useTranslate()
  const [openMenu, setOpenMenu] = useState(false)

  const noOptionsMessage = ({ inputValue }) => {
    if (inputValue) return `Не найдено "${inputValue}"`
    return t('common_select_not_found_message')
  }

  const loadingMessage = () => {
    return t('common_loading_message')
  }

  const handleMenuOpen = () => {
    setOpenMenu(true)
    if (typeof onMenuOpen === 'function') {
      onMenuOpen()
    }
  }

  const handleMenuClose = (event) => {
    setOpenMenu(false)
    if (typeof onMenuClose === 'function') {
      onMenuClose()
    }
  }

  const _onChange = (option, action) => {
    if (typeof onChange === 'function') {
      onChange(option)
    }
  }

  const styles = getSelectStyles(theme, {
    error,
    height,
    menuIsOpen: openMenu
  })

  const closeMenuOnSelect = !restProps.isMulti
  const menuIsOpen = isLoading ? false : openMenu
  const openMenuOnClick = type === 'select'

  const formattedPlaceholder = placeholder || t('common_select_placeholder')

  return (
    <SelectWrapper>
      <FieldLabel error={error}>{label}</FieldLabel>
      <ReactSelect
        ref={ref}
        options={options}
        classNamePrefix={'select'}
        styles={styles}
        closeMenuOnSelect={closeMenuOnSelect}
        isLoading={isLoading}
        menuIsOpen={menuIsOpen}
        openMenuOnClick={openMenuOnClick}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        noOptionsMessage={noOptionsMessage}
        loadingMessage={loadingMessage}
        onChange={_onChange}
        menuPortalTarget={document.body}
        placeholder={formattedPlaceholder}
        {...restProps}
      />
      <FieldError>{error}</FieldError>
    </SelectWrapper>
  )
})

Select.propTypes = {
  theme: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.oneOf([
    'select',
    'autocomplete'
  ]),
  isMulti: PropTypes.bool,
  isLoading: PropTypes.bool,
  isCreatable: PropTypes.bool,
  options: PropTypes.array.isRequired,
  onMenuOpen: PropTypes.func,
  onMenuClose: PropTypes.func,
  onCreate: PropTypes.func,
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

Select.defaultProps = {
  type: 'select',
  isCreatable: false,
  isMulti: false
}

export default withTheme(Select)
