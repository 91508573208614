// /* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { path, prop } from 'ramda'
import { sprintf } from 'sprintf-js'
import styled, { css } from 'styled-components'

import { companyRegSerializer } from '../../serializers'
import RegistrationBox from '../RegistrationBox'
import SubTitle from './SubTitle'

import * as API from 'constants/api'
import { useTranslate } from 'utils/translate'
import { useCreate, useEImzoSign, useUpdate } from 'hooks'
import { useReg } from 'contexts/RegistrationProvider'
import { useErrorDialog } from 'components/ErrorDialog'
import { useImzoDialog } from 'components/EImzoDialog'
import Title from 'components/Title'
import { FieldLabel } from 'components/FormComponents'
import { CancelButton, PrimaryButton } from 'components/Buttons'

const MainTitle = styled(Title)`
  margin-bottom: 45px;
`

const Block = styled.div`
  border-bottom: 1px solid ${props => props.theme.palette.toggle.shapeColor};
  margin-bottom: 40px;
  padding-bottom: 40px;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 35px 50px;

  & + & {
    margin-top: 35px;
  }
`

const GridItem = styled.div`
  ${({ isSingle }) => isSingle && (
    css`
      &:not(:last-child) {
        margin-bottom: 35px;
      }
    `
  )}
`

const GridItemLabel = styled(FieldLabel)``

const GridItemText = styled.div`
  color: ${props => props.theme.palette.text.opacity};
`

const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: 285px auto;
  grid-gap: 24px;
`

const RegPreview = props => {
  const { toPrevStep, toNextStep } = props

  const { t } = useTranslate()

  const { state, dispatch } = useReg()

  const id = prop('id', state)

  const regCreate = id ? {} : useCreate(API.REGISTRATION_CREATE)
  const regUpdate = id ? useUpdate(sprintf(API.REGISTRATION_UPDATE, id)) : {}

  const errorDialog = useErrorDialog()
  const imzoDialog = useImzoDialog()
  const { getCompanyCardText } = useEImzoSign({ company: state })

  const tin = prop('tin', state)
  const ownership = path(['ownership', 'name'], state)
  const companyName = prop('name', state)
  const ecoActivity = path(['ecoActivity', 'name'], state)
  const businessType = path(['businessType', 'name'], state)

  const phoneNumber = prop('phoneNumber', state)
  const email = prop('email', state)
  const webSite = prop('webSite', state)

  const address = prop('address', state)
  const legalIsPhysical = prop('legalIsPhysical', state)

  const legalRegion = path(['legal', 'region', 'name'], address)
  const legalDistrict = path(['legal', 'district', 'name'], address)
  const legalStreet = path(['legal', 'street'], address)
  const legalHouseNumber = path(['legal', 'houseNumber'], address)
  const legalPostcode = path(['legal', 'postcode'], address)

  const physicalRegion = legalIsPhysical ? legalRegion : path(['actual', 'region', 'name'], address)
  const physicalDistrict = legalIsPhysical ? legalDistrict : path(['actual', 'district', 'name'], address)
  const physicalStreet = legalIsPhysical ? legalStreet : path(['actual', 'street'], address)
  const physicalHouseNumber = legalIsPhysical ? legalHouseNumber : path(['actual', 'houseNumber'], address)
  const physicalPostcode = legalIsPhysical ? legalPostcode : path(['actual', 'postcode'], address)

  const bankCode = prop('bankCode', state)
  const bankName = prop('bankName', state)
  const checkingAccount = prop('checkingAccount', state)

  const staff = prop('staff', state)
  const responsibleName = path(['personInCharge', 'name'], staff)
  const responsiblePosition = path(['personInCharge', 'position'], staff)
  const responsiblePhone = path(['personInCharge', 'phone'], staff)
  const responsibleEmail = path(['personInCharge', 'email'], staff)

  const directorName = path(['head', 'name'], staff)
  const directorPosition = path(['head', 'position'], staff)
  const directorPhone = path(['head', 'phone'], staff)
  const directorEmail = path(['head', 'email'], staff)

  const accountantName = path(['accountant', 'name'], staff)
  const accountantPhone = path(['accountant', 'phone'], staff)
  const accountantEmail = path(['accountant', 'email'], staff)

  const onConfirm = () => {
    const signText = getCompanyCardText()

    window.scrollTo({ top: 0 })

    imzoDialog({
      text: signText,
      onSuccess: (key, keyTin, keyParams) => {
        const { validTo, serialNumber } = keyParams

        const serializedValues = companyRegSerializer({
          ...state,
          key,
          validTo,
          serialNumber
        })

        if (id) {
          return regUpdate.update(serializedValues)
            .then(() => {
              toNextStep()
            })
            .catch(error => {
              errorDialog({ message: error })
            })
        }

        return regCreate.create(serializedValues)
          .then(({ id, contractNumber }) => {
            dispatch({ id, contractNumber })
            toNextStep()
          })
          .catch(error => {
            errorDialog({ message: error })
          })
      }
    })
  }

  return (
    <RegistrationBox padding={'0'} width={'700px'}>
      <section>
        <MainTitle description={t('registration_preview_description')}>
          {t('registration_preview_title')}
        </MainTitle>

        <Block>
          <SubTitle>{t('registration_company_title')}</SubTitle>
          <Grid>
            <GridItem>
              <GridItemLabel>{t('registration_company_name_input_label')}</GridItemLabel>
              <GridItemText>{ownership} {companyName}</GridItemText>
            </GridItem>
            <GridItem>
              <GridItemLabel>{t('registration_preview_tin')}</GridItemLabel>
              <GridItemText>{tin}</GridItemText>
            </GridItem>
            <GridItem>
              <GridItemLabel>{t('registration_company_business_type_input_label')}</GridItemLabel>
              <GridItemText>{businessType}</GridItemText>
            </GridItem>
            <GridItem>
              <GridItemLabel>{t('registration_company_oked_input_label')}</GridItemLabel>
              <GridItemText>{ecoActivity}</GridItemText>
            </GridItem>
          </Grid>
        </Block>

        <Block>
          <SubTitle>{t('registration_contacts_title')}</SubTitle>
          <Grid>
            <GridItem>
              <GridItemLabel>{t('registration_preview_company_phone')}</GridItemLabel>
              <GridItemText>{phoneNumber}</GridItemText>
            </GridItem>
            <GridItem>
              <GridItemLabel>{t('registration_preview_company_email')}</GridItemLabel>
              <GridItemText>{email}</GridItemText>
            </GridItem>
            {webSite && (
              <GridItem>
                <GridItemLabel>{t('registration_preview_company_website')}</GridItemLabel>
                <GridItemText>{webSite}</GridItemText>
              </GridItem>
            )}
          </Grid>

          <Grid>
            <GridItem>
              <GridItemLabel>{t('registration_contacts_legal_address_label')}</GridItemLabel>
              <GridItemText>
                <GridItemText>{legalPostcode}</GridItemText>
                <GridItemText>{legalRegion}</GridItemText>
                <GridItemText>{legalDistrict}</GridItemText>
                <GridItemText>{legalStreet}</GridItemText>
                <GridItemText>{legalHouseNumber}</GridItemText>
              </GridItemText>
            </GridItem>
            <GridItem>
              <GridItemLabel>{t('registration_contacts_physical_address_label')}</GridItemLabel>
              <GridItemText>
                {physicalPostcode && <GridItemText>{physicalPostcode}</GridItemText>}
                {physicalRegion && <GridItemText>{physicalRegion}</GridItemText>}
                {physicalDistrict && <GridItemText>{physicalDistrict}</GridItemText>}
                {physicalStreet && <GridItemText>{physicalStreet}</GridItemText>}
                {physicalHouseNumber && <GridItemText>{physicalHouseNumber}</GridItemText>}
              </GridItemText>
            </GridItem>
          </Grid>
        </Block>

        <Block>
          <SubTitle>{t('registration_bank_title')}</SubTitle>
          <GridItem isSingle={true}>
            <GridItemLabel>{t('registration_bank_code_input_label')}</GridItemLabel>
            <GridItemText>{bankCode}</GridItemText>
          </GridItem>
          <GridItem isSingle={true}>
            <GridItemLabel>{t('registration_bank_name_input_label')}</GridItemLabel>
            <GridItemText>{bankName}</GridItemText>
          </GridItem>
          <GridItem isSingle={true}>
            <GridItemLabel>{t('registration_bank_account_code_input_label')}</GridItemLabel>
            <GridItemText>{checkingAccount}</GridItemText>
          </GridItem>
        </Block>

        <Block>
          <SubTitle>{t('registration_staff_title')}</SubTitle>
          <Grid>
            <GridItem>
              <GridItemLabel>{t('registration_staff_director_name_input_label')}</GridItemLabel>
              <GridItemText>
                <GridItemText>{directorName}</GridItemText>
                <GridItemText>{directorPosition}</GridItemText>
                <GridItemText>{directorPhone}</GridItemText>
                <GridItemText>{directorEmail}</GridItemText>
              </GridItemText>
            </GridItem>
            {accountantName && (
              <GridItem>
                <GridItemLabel>{t('registration_staff_accountant_name_input_label')}</GridItemLabel>
                <GridItemText>
                  <GridItemText>{accountantName}</GridItemText>
                  <GridItemText>{accountantPhone}</GridItemText>
                  <GridItemText>{accountantEmail}</GridItemText>
                </GridItemText>
              </GridItem>
            )}
            <GridItem>
              <GridItemLabel>{t('registration_staff_responsible_name_input_label')}</GridItemLabel>
              <GridItemText>
                <GridItemText>{responsibleName}</GridItemText>
                <GridItemText>{responsiblePosition}</GridItemText>
                <GridItemText>{responsiblePhone}</GridItemText>
                <GridItemText>{responsibleEmail}</GridItemText>
              </GridItemText>
            </GridItem>
          </Grid>
        </Block>
      </section>

      <ButtonGroup>
        <CancelButton onClick={toPrevStep}>
          {t('common_button_back')}
        </CancelButton>
        <PrimaryButton type={'button'} onClick={onConfirm}>
          {t('common_button_confirm_and_sign')}
        </PrimaryButton>
      </ButtonGroup>
    </RegistrationBox>
  )
}

RegPreview.propTypes = {
  toPrevStep: PropTypes.func.isRequired,
  toNextStep: PropTypes.func.isRequired,
  toTINStep: PropTypes.func.isRequired
}

export default RegPreview
