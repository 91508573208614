import React from 'react'
import Registration from '../components/Registration'
import { RegistrationProvider } from 'contexts/RegistrationProvider'
// import { useCreate } from 'hooks'
// import * as API from 'constants/api'
// import { companyRegSerializer } from '../serializers'

const RegistrationContainer = () => {
  // const regCreate = useCreate(API.REGISTRATION_CREATE)
  //
  // const onSubmit = (formValues, formMethods) => {
  //   const serializedValues = companyRegSerializer(formValues)
  //   regCreate.create(serializedValues, formMethods)
  // }

  return (
    <RegistrationProvider>
      <Registration />
    </RegistrationProvider>
  )
}

RegistrationContainer.propTypes = {}

export default RegistrationContainer
