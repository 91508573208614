import PropTypes from 'prop-types'
import { curry, find, path, pathOr, propEq } from 'ramda'
import request from 'utils/request'
import { getDataFromSuccess } from 'hooks/api/utils'

export const getOptions = ({ api, search, params, pageSize = 100 }) => {
  const formedParams = { pageSize, search, ...params }
  return request
    .get(api, formedParams)
    .then(pathOr([], ['data', 'results']))
}

export const getOption = (api, detailApi) => id => {
  const url = detailApi ? `${detailApi}${id}` : `${api}${id}/`
  return request
    .get(url)
    .then(getDataFromSuccess)
}

export const getStaticOptions = (search, list) => Promise.resolve(list)

export const getStaticOption = (id, list) => Promise.resolve(find(propEq('id', id))(list))

export const defaultGetText = curry((text, obj) => path(text, obj))

export const defaultGetValue = curry((value, obj) => path(value, obj))

export const searchFieldPropTypes = {
  api: PropTypes.string.isRequired,
  detailApi: PropTypes.string,
  params: PropTypes.object,
  itemText: PropTypes.array,
  parent: PropTypes.any
}

export const searchFieldStaticPropTypes = {
  list: PropTypes.array.isRequired,
  itemText: PropTypes.array
}
