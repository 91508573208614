import React, { useContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Context from './context'
import AlertIcon from './AlertIcon'

import hexToRgba from 'utils/hexToRgba'
import { useTranslate } from 'utils/translate'
import Modal from 'components/Modal'
import { CancelButton, SecondaryButton } from 'components/Buttons'
import { dialogReducer } from 'reducers'

const initialState = {
  isOpen: false,
  message: null,
  button: null,
  width: '500px'
}

export const useErrorDialog = () => {
  const { onOpen } = useContext(Context)
  return onOpen
}

const Message = styled.div`
  background-color: ${props => props.theme.palette.input.background};
  border-radius: ${props => props.theme.shape.borderRadius};
  border: 1px solid ${props => props.theme.palette.input.border};
  font-family: Consolas, monospace;
  padding: 15px;
  margin-bottom: 30px;
  white-space: pre-wrap;
  word-break: break-word;
`

const TitleWrap = styled.div`
  align-items: center;
  display: flex;
`

const Title = styled.div`

`

const IconWrap = styled.div`
  align-items: center;
  background-color: ${props => hexToRgba(props.theme.palette.error, 0.15)};
  border-radius: 50%;
  color: ${props => props.theme.palette.error};
  display: flex;
  justify-content: center;
  height: 32px;
  margin-right: 8px;
  width: 32px;
`

const ButtonGroup = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(2, 1fr);
`

const ErrorTitle = ({ children }) => {
  return (
    <TitleWrap>
      <IconWrap>
        <AlertIcon fontSize={'22px'} />
      </IconWrap>
      <Title>{children}</Title>
    </TitleWrap>
  )
}

const ErrorProvider = ({ children }) => {
  const { t } = useTranslate()
  const [state, dispatch] = useReducer(dialogReducer, initialState)

  const onOpen = payload => {
    dispatch({
      type: 'open',
      payload
    })
  }

  const onClose = () => {
    dispatch({ type: 'close' })
  }

  const errors = state.message
    ? JSON.stringify(state.message, null, 4)
    : state.message

  const title = <ErrorTitle>{t('common_error')}</ErrorTitle>

  const onClickAction = event => {
    onClose()
    if (typeof state.button.action === 'function') {
      state.button.action(event)
    }
  }

  return (
    <Context.Provider value={{ onOpen, onClose }}>
      {children}
      <Modal
        title={title}
        isOpen={state.isOpen}
        onClose={onClose}
        maskClosable={false}
        showCloseIcon={false}
        width={state.width}>
        <Message>
          {errors}
        </Message>

        {state.button?.text
          ? (
            <ButtonGroup>
              <CancelButton onClick={onClose}>
                {t('common_button_close')}
              </CancelButton>
              <SecondaryButton onClick={onClickAction}>
                {state.button.text}
              </SecondaryButton>
            </ButtonGroup>
          )
          : (
            <CancelButton fullWidth={true} onClick={onClose}>
              {t('common_button_close')}
            </CancelButton>
          )}
      </Modal>
    </Context.Provider>
  )
}

ErrorTitle.propTypes = {
  children: PropTypes.node.isRequired
}

ErrorProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default ErrorProvider
