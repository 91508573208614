import styled from 'styled-components'
import Logo from 'assets/logo.svg'

export default styled.div`
  background-image: url(${Logo});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 51px;
  width: 111px;
`
