import React from 'react'
import styled from 'styled-components'
import { useTranslate } from 'utils/translate'

const Wrap = styled.div`
  margin: auto;
  text-align: center;
  max-width: 525px;
  & p:last-child {
    margin-top: 50px;
  }
`

const ThankYou = () => {
  const { t } = useTranslate()

  const phone = process.env.REACT_APP_GS1_PHONE_NUMBER

  return (
    <Wrap key={'thanks'}>
      <h2>{t('thanks_heading')}</h2>
      <p>{t('thanks_text_1')}</p>
      <p>{t('thanks_text_2')}</p>
      <p>{t('thanks_text_3')}</p>
      <p>{t('thanks_text_4', { phone })}</p>
    </Wrap>
  )
}

export default ThankYou
