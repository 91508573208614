import React from 'react'
import styled from 'styled-components'

const SList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-position: inside;
`

const SListMain = styled(SList)`
  list-style-type: none;
  margin: 10px 0;
  white-space: pre-line;
`

export const TList = props => (
  <SList {...props} />
)

export const TListMain = props => (
  <SListMain as={'ol'} {...props} />
)
