import React from 'react'
import PropTypes from 'prop-types'
import { path, prop } from 'ramda'
import dayjs from 'dayjs'

import {
  Wrap,
  Table,
  TData,
  TRow,
  TRowHalf,
  TableFooter,
  ContractsParagraphs
} from './components'

import { useTranslate } from 'utils/translate'
import { Text, Flex } from 'components/UI'
import { formatContractNumber } from 'utils/transformData'

const DocsContract = props => {
  const {
    data,
    company,
    contractNumber: contractNumberFromProps = '',
  } = props

  const { t } = useTranslate()

  const isLoading = prop('isLoading', data)
  const contractData = path(['detail', 'data'], data) || []
  const lastParagraphNumber = contractData.length + 1

  const contractNumberFromCompany = prop('contractNumber', company)
  const contractNumber = formatContractNumber(contractNumberFromProps || contractNumberFromCompany)
  const ownership = path(['ownership', 'name'], company)
  const companyName = prop('name', company)
  const companyNameFull = `${ownership} ${companyName}`
  const directorFio = path(['staff', 'head', 'name'], company)

  const today = new Date()
  const date = dayjs(today).format('DD.MM.YYYY')

  const legalAddress = path(['address', 'legal'], company)
  const legalRegion = path(['region', 'name'], legalAddress)
  const legalDistrict = path(['district', 'name'], legalAddress)
  const legalStreet = prop('street', legalAddress)
  const legalHouseNumber = prop('houseNumber', legalAddress)
  const legalPostcode = prop('postcode', legalAddress)
  const fullAddress = `${legalPostcode}, ${legalRegion}, ${legalDistrict}, ${legalStreet}, ${legalHouseNumber}`

  const tin = prop('tin', company)
  const bankCode = prop('bankCode', company)
  const bankName = prop('bankName', company)
  const checkingAccount = prop('checkingAccount', company)

  if (isLoading) {
    return (
      <div>Loading contract...</div>
    )
  }

  return (
    <Wrap>
      <Table cellPadding="0" cellSpacing="0">
        <tbody>
          <TRowHalf>
            <TData>
              <Text fontWeight={500} className="margin-bottom">
                <Text align={'center'} transform={'uppercase'}>{contractNumber}№ Shartnoma</Text>
                <Text align={'center'}>GS1 Uzbekistan Uyushmasiga a’zolik to’g’risida</Text>
              </Text>

              <Flex align={'center'} justify={'space-between'} className="margin-bottom">
                <div>Toshkent sh.</div>
                <div>{date}</div>
              </Flex>

              <div className="margin-bottom">
                GS1 Uzbekistan Uyushmasi nomidan Ustav asosida faoliyat yurituvchi Uyushma Raisi N.Sabirov orqali
                keyingi o’rinlarda “Uyushma” deb yuritiladi bir tomondan va {companyNameFull} {'ustav'} asosida
                faoliyat yurituvchi {directorFio} orqali, keyingi o’rinlarda “Uyushma A’zosi” deb yuritiladi, boshqa
                tomondan, ushbu shartnomani quyidagilar haqida tuzdilar:
              </div>
            </TData>

            <TData>
              <Text fontWeight={500} className="margin-bottom">
                <Text align={'center'} transform={'uppercase'}>Договор №{contractNumber}</Text>
                <Text align={'center'} className="center">о членстве в Ассоциации GS1 Uzbekistan</Text>
              </Text>

              <Flex align={'center'} justify={'space-between'} className="margin-bottom">
                <div>г. Ташкент</div>
                <div>{date}</div>
              </Flex>

              <div className="margin-bottom">
                Ассоциация GS1 Uzbekistan в лице Председателя Ассоциации Н. Сабирова, действующего на основании
                Устава, именуемая в дальнейшем Ассоциация, с одной стороны и {companyNameFull} в
                лице {directorFio} действующего на основании {'устава'} именуемый в дальнейшем "Член
                Ассоциации", с другой стороны, заключили настоящий договор о нижеследующем:
              </div>
            </TData>
          </TRowHalf>

          <ContractsParagraphs data={contractData} />
        </tbody>

        <tbody>
          <TRow>
            <TData colSpan="2">
              <Text align={'center'} fontWeight={500} transform={'uppercase'}>
                {lastParagraphNumber}. Tomonlarning yuridik manzili / Юридические адреса сторон
              </Text>
            </TData>
          </TRow>

          <TRowHalf border={false}>
            <TData className="cell-padding">
              <div className="margin-bottom">
                <div>GS1 Uzbekistan Uyushmasi</div>
                <div>100031, Toshkent sh., Afrosiyob ko'chasi, 16</div>
                <div>h/r 2020 8000 3039 7181 2001, "Mikrokreditbank"</div>
                <div>Bank kodi 00433 STIR 202 719 734, IFUT 91110</div>
              </div>

              <div className="margin-bottom-big">
                <div>Ассоциация GS1 Uzbekistan</div>
                <div>100031, г. Ташкент, ул. Афросиаб, 16</div>
                <div>р/с 2020 8000 3039 7181 2001, "Микрокредитбанк"</div>
                <div>Код банка 00433 ИНН 202 719 734, ОКЭД 91110</div>
              </div>
            </TData>
            <TData className="cell-padding">
              <div className="margin-bottom-big">
                <div>{companyNameFull}</div>
                <div>{fullAddress}</div>

                <div>{checkingAccount} {bankName}</div>
                <div>
                  {t('registration_bank_code_input_label')}: {bankCode} {t('registration_tin_input_label')}: {tin}
                </div>
              </div>
            </TData>
          </TRowHalf>

          <TRowHalf>
            <TData>
              <div>Rais/Председатель</div>
              <div>N. Sabirov</div>
            </TData>
            <TData />
          </TRowHalf>
        </tbody>
      </Table>
      <TableFooter />
    </Wrap>
  )
}

DocsContract.propTypes = {
  data: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  contractNumber: PropTypes.string
}

export default DocsContract
