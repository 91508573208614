import styled from 'styled-components'

const Wrap = styled.div`
  font-weight: normal;
  line-height: 1.5;
  color: ${props => props.theme.palette.accent.default};
  margin: 0 auto;
  padding: 1rem;
  
  & .margin-bottom:not(:last-child) {
    margin-bottom: 15px;
  }
  
  & .margin-bottom-big:not(:last-child) {
    margin-bottom: 30px;
  }
`

export default Wrap
