import { useState, useEffect, useRef } from 'react'

const usePrevious = value => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export default ({
  isOpen,
  fadeDuration = 300
}) => {
  let timeout = null
  const [fadeType, setFadeType] = useState(null)
  const [isRendered, setIsRendered] = useState(isOpen)
  const prevIsOpen = usePrevious(isOpen)

  const onOpen = () => {
    if (isOpen && isOpen !== prevIsOpen) {
      setIsRendered(true)
      setTimeout(() => setFadeType('in'), 0)
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }

  const onClose = () => {
    if (!isOpen && isOpen !== prevIsOpen) {
      setFadeType('out')
      timeout = setTimeout(() => {
        setIsRendered(false)
      }, fadeDuration)
    }
  }

  useEffect(() => {
    if (isOpen) {
      timeout = setTimeout(() => setFadeType('in'), 0)
    }
  }, [])

  useEffect(() => {
    onOpen()
    onClose()
  }, [isOpen])

  return {
    isRendered,
    fadeType
  }
}
