const PROTOCOL = 'https://'
const DOMAIN_URL = 'api.478.uz'
const API_VERSION = 'v1'

export const BASE_API_URL = `${PROTOCOL}${DOMAIN_URL}/api`

// Проверка ИНН
const TIN = `${API_VERSION}/check_tin`
export const CHECK_TIN = `/${TIN}/`

const ME = `${API_VERSION}/me`
export const GET_ME = `/${ME}/`

// Получение данных банка
const BANK = `${API_VERSION}/bank`
export const BANK_GET_CODE = `/${BANK}/get_code/`

// Регистрация компании / подпись документов
const CATALOG = 'national_catalog'
export const REGISTRATION_CREATE = `/${CATALOG}/companies/`
export const REGISTRATION_UPDATE = `/${CATALOG}/companies/%d/`
export const SIGN_CONTRACT_POST = `/${CATALOG}/companies/%s/sign/`

const AGREEMENT_PDF = `${API_VERSION}/agreement`
export const AGREEMENT_PDF_DETAIL = `/${AGREEMENT_PDF}/%s/`

const CONTRACT_PDF = `${API_VERSION}/contract_pdf`
export const CONTRACT_PDF_DETAIL = `/${CONTRACT_PDF}/%s/`

// Списки селектов
const BUSINESS_ACTIVITIES = `${API_VERSION}/business-activities`
export const BUSINESS_ACTIVITIES_LIST = `/${BUSINESS_ACTIVITIES}/`

const BUSINESS_TYPES = `${API_VERSION}/business_type`
export const BUSINESS_TYPES_LIST = `/${BUSINESS_TYPES}/`

const OWNERSHIPS = `${API_VERSION}/ownerships`
export const OWNERSHIPS_LIST = `/${OWNERSHIPS}/`

const OKED = `${API_VERSION}/economic-activities`
export const OKED_LIST = `/${OKED}/`

const REGIONS = `${API_VERSION}/geo/regions`
export const REGIONS_LIST = `/${REGIONS}/`

const DISTRICTS = `${API_VERSION}/districts`
export const DISTRICTS_LIST = `/${DISTRICTS}/`

// Доп. вопросы
const QUESTIONS = `${API_VERSION}/questions`
export const QUESTIONS_LIST = `/${QUESTIONS}/`
