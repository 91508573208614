import React, { forwardRef, Fragment } from 'react'
import PropTypes from 'prop-types'
import { useFieldError } from 'hooks'
import { FieldError, FieldLabel } from '../Utils'
import BaseInput from './BaseInput'

const Input = forwardRef((props, ref) => {
  const { name, label, labelHint, ...restProps } = props

  const error = useFieldError(name)

  return (
    <Fragment>
      <FieldLabel error={error} hint={labelHint}>{label}</FieldLabel>
      <BaseInput name={name} ref={ref} error={error} {...restProps} />
      <FieldError>{error}</FieldError>
    </Fragment>
  )
})

Input.displayName = 'Input'

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelHint: PropTypes.string
}

export default Input
