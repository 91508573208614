/* eslint-disable max-len */
import React from 'react'
import { SVG } from 'components/Utils'

export default props => (
  <SVG {...props}>
    <path
      d="M13.9,12l5.1-5.1c0.5-0.5,0.5-1.4,0-1.9c-0.5-0.5-1.4-0.5-1.9,0L12,10.1L6.9,5.1c-0.5-0.5-1.4-0.5-1.9,0s-0.5,1.4,0,1.9 l5.1,5.1l-5.1,5.1c-0.5,0.5-0.5,1.4,0,1.9c0.3,0.3,0.6,0.4,0.9,0.4s0.7-0.1,0.9-0.4l5.1-5.1l5.1,5.1c0.3,0.3,0.6,0.4,0.9,0.4 s0.7-0.1,0.9-0.4c0.5-0.5,0.5-1.4,0-1.9L13.9,12z" />
  </SVG>
)
