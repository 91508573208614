import { useReducer, useCallback } from 'react'
import { pipe, toPairs, map } from 'ramda'
import { reducer, PENDING, SUCCESS, FAIL, initialState } from './state'
import { getDataFromSuccess, getDataFromError } from './utils'
import request from 'utils/request'

export default url => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const requestCallback = useCallback((data, form) => {
    dispatch({ type: PENDING })

    return request.post(url, data)
      .then(response => {
        const data = getDataFromSuccess(response)
        dispatch({ type: SUCCESS, payload: data })

        return data
      })
      .catch(response => {
        const error = getDataFromError(response)
        dispatch({ type: FAIL, payload: error })

        if (form && form.setError) {
          pipe(
            toPairs,
            map(([field, message]) => {
              if (field !== 'nonFieldErrors') {
                form.setError(field, {
                  type: 'manual',
                  message
                })
              }
            })
          )(error)
        }

        return Promise.reject(error)
      })
  }, [request, url])

  return { ...state, post: requestCallback }
}
