import React, { useEffect, useState } from 'react'
import { prop } from 'ramda'
import styled, { css } from 'styled-components'

import sections from './sections'

import { useTranslate } from 'utils/translate'

function debounce (func, wait) {
  let timeout

  return function executedFunction () {
    const context = this
    const args = arguments

    const later = function () {
      timeout = null
      func.apply(context, args)
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

const SideAnchor = styled.div`
  align-self: baseline;
  position: sticky;
  top: 50px;
  width: 250px;
`

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  &:after {
    background-color: #e7e9ea;
    border-radius: 4px;
    content: "";
    position: absolute;
    top: 0;
    left: -30px;
    bottom: 0;
    width: 2px;
  }
`

const ListItem = styled.li`
  & + & {
    margin-top: 30px;
  }
`

const Anchor = styled('div')`
  color: ${props => props.isActive ? props.theme.palette.text.primary : '#999da7'};
  cursor: pointer;
  display: block;
  text-decoration: none;
  position: relative;
  transition: ${props => props.theme.transition};
  &:after {
    background-color: transparent;
    border-radius: 4px;
    content: "";
    position: absolute;
    top: -10px;
    left: -30px;
    bottom: -10px;
    transition: ${props => props.theme.transition};
    width: 2px;
    z-index: 1;
  }
  ${props => props.isActive && (
    css`
      &:after {
        background-color: ${props => props.theme.palette.primary.default};
      }
    `
  )}
`

const getScrollTop = () => {
  return window.pageYOffset || document.documentElement.scrollTop
}

const RegAnchors = () => {
  const { t } = useTranslate()

  const listFirstHash = sections[0].hash
  const [activeHash, setActiveHash] = useState(listFirstHash)

  const onScroll = () => {
    const scrollVal = getScrollTop()

    sections.forEach(section => {
      const hash = prop('hash', section)
      const isFirst = hash === listFirstHash
      const el = document.querySelector(hash)
      const elRect = el ? el.getBoundingClientRect() : {}
      const elHeight = elRect.height
      const startPoint = isFirst ? 0 : scrollVal + elRect.top
      const endPoint = (startPoint + elHeight)

      if ((scrollVal >= startPoint) && (scrollVal <= endPoint)) {
        setActiveHash(hash)
      }
    })
  }

  const scrollToSection = hash => {
    const scrollVal = getScrollTop()
    const element = document.querySelector(hash)
    const isFirst = hash === listFirstHash

    if (element) {
      const elementRect = element.getBoundingClientRect()
      const elementPositionTop = elementRect.top + scrollVal

      window.scrollTo({
        top: isFirst ? 0 : elementPositionTop,
        behavior: 'smooth'
      })
    }
  }

  useEffect(() => {
    const debounceOnScroll = debounce(onScroll, 100)
    window.addEventListener('scroll', debounceOnScroll)

    return () => {
      window.removeEventListener('scroll', debounceOnScroll)
    }
  }, [])

  return (
    <SideAnchor>
      <List>
        {sections.map(anchor => {
          const hash = prop('hash', anchor)
          const title = prop('title', anchor)
          const isActive = hash === activeHash

          return (
            <ListItem key={hash}>
              <Anchor
                isActive={isActive}
                onClick={() => scrollToSection(hash)}>
                {t(title)}
              </Anchor>
            </ListItem>
          )
        })}
      </List>
    </SideAnchor>
  )
}

RegAnchors.propTypes = {}

export default RegAnchors
