import React, { createContext, useReducer, useContext } from 'react'
import PropTypes from 'prop-types'

const AdmissionContractContext = createContext({})

const admissionContractReducer = (state, action) => ({ ...state, ...action })

export const AdmissionContractProvider = ({ children }) => {
  const initialState = {
    /// values
  }

  const [state, dispatch] = useReducer(admissionContractReducer, initialState)

  return (
    <AdmissionContractContext.Provider value={{ state, dispatch }}>
      {children}
    </AdmissionContractContext.Provider>
  )
}

AdmissionContractProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const useAdmissionContract = () => useContext(AdmissionContractContext)

export { AdmissionContractContext }
