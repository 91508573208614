export default (theme, params) => ({
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? theme.palette.common.white
      : params.error
        ? theme.palette.input.backgroundError
        : theme.palette.input.background,
    boxShadow: null,
    borderRadius: theme.shape.borderRadius,
    borderColor: state.isFocused
      ? params.error
        ? `${theme.palette.error} !important`
        : theme.palette.primary.default
      : 'transparent',
    transition: theme.transition,
    height: params.height,
    minHeight: params.height ? 'unset' : theme.shape.select.height,
    '&:hover': {
      backgroundColor: state.isFocused
        ? theme.palette.common.white
        : params.error
          ? theme.palette.input.backgroundError
          : theme.palette.input.backgroundHover,
      borderColor: state.isFocused ? theme.palette.primary.default : 'transparent'
    }
  }),
  indicatorSeparator: () => ({}),
  loadingIndicator: (provided, state) => ({
    ...provided,
    '& span': {
      background: state.isFocused
        ? theme.palette.input.label
        : theme.palette.input.placeholder
    }
  }),
  clearIndicator: provided => ({
    ...provided,
    color: theme.palette.input.placeholder,
    '&:hover': {
      color: theme.palette.input.label
    }
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    alignItems: 'center',
    color: state.isFocused
      ? params.error
        ? `${theme.palette.error} !important`
        : theme.palette.primary.default
      : theme.palette.input.placeholder,
    padding: '0 12px',
    transition: theme.transition,
    transform: params.menuIsOpen ? 'rotate(180deg)' : 'rotate(0)',
    '&:hover': {
      color: theme.palette.input.label
    }
  }),
  menuPortal: provided => ({
    ...provided,
    zIndex: 1500
  }),
  menu: (provided, state) => {
    const { placement } = state

    const positionParams = placement === 'bottom'
      ? { top: 'calc(100% + 4px)' }
      : { bottom: 'calc(100% + 4px)' }

    return {
      ...provided,
      border: '1px solid',
      borderColor: theme.palette.input.border,
      boxShadow: theme.shape.select.menu.boxShadow,
      borderRadius: theme.shape.borderRadius,
      margin: '0',
      ...positionParams
    }
  },
  menuList: provided => ({
    ...provided,
    padding: '10px'
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected
      ? theme.palette.input.backgroundHover
      : state.isFocused
        ? theme.palette.input.background
        : 'none',
    borderRadius: theme.shape.select.option.borderRadius,
    color: 'inherit',
    cursor: 'pointer',
    padding: theme.shape.select.option.padding,
    transition: theme.transition,
    '&:active': {
      background: theme.palette.input.backgroundHover
    }
  }),
  valueContainer: (provided, state) => {
    const isMultiWithValues = state.hasValue && state.isMulti
    return {
      ...provided,
      padding: isMultiWithValues ? '4px' : '0 20px'
    }
  },
  singleValue: provided => ({
    ...provided,
    fontSize: 'inherit',
    color: 'inherit'
  }),
  placeholder: provided => ({
    ...provided,
    color: theme.palette.input.placeholder,
    fontSize: 'inherit',
    margin: '0'
  }),
  noOptionsMessage: provided => ({
    ...provided,
    color: theme.palette.input.placeholder
  }),
  loadingMessage: provided => ({
    ...provided,
    color: theme.palette.input.placeholder
  }),

  multiValue: (provided, state) => {
    const hasValueFocused = state.hasValue && params.menuIsOpen
    return {
      ...provided,
      backgroundColor: hasValueFocused
        ? theme.palette.input.background
        : '#FAFBFB',
      border: '1px solid transparent',
      borderRadius: theme.shape.borderRadius,
      transition: theme.transition,
      margin: '4px',
      '&:hover': {
        borderColor: theme.palette.input.border
      }
    }
  },
  multiValueLabel: provided => ({
    ...provided,
    fontSize: 'inherit',
    padding: '7px 0',
    paddingLeft: '12px',
    paddingRight: '6px'
  }),
  multiValueRemove: provided => ({
    ...provided,
    alignSelf: 'center',
    borderRadius: '50%',
    color: theme.palette.input.placeholder,
    cursor: 'pointer',
    justifyContent: 'center',
    paddingLeft: '0',
    paddingRight: '0',
    marginRight: '12px',
    transition: theme.transition,
    height: '20px',
    width: '20px',
    '&:hover': {
      backgroundColor: '#8591A3',
      color: 'white'
    },
    '& svg': {
      height: '16px',
      width: '16px'
    }
  })
})
