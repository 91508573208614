import RegCompany from './RegMainFields/RegCompany'
import RegContacts from './RegMainFields/RegContacts'
import RegStaff from './RegMainFields/RegStaff'
import RegBank from './RegMainFields/RegBank'
import RegQuestions from './RegMainFields/RegQuestions'

export default [
  {
    id: 'company',
    hash: '#company',
    title: 'registration_company_title',
    description: 'registration_company_description',
    Component: RegCompany
  },
  {
    id: 'contacts',
    hash: '#contacts',
    title: 'registration_contacts_title',
    description: 'registration_contacts_description',
    Component: RegContacts
  },
  {
    id: 'staff',
    hash: '#staff',
    title: 'registration_staff_title',
    description: 'registration_staff_description',
    Component: RegStaff
  },
  {
    id: 'bank',
    hash: '#bank',
    title: 'registration_bank_title',
    description: 'registration_bank_description',
    Component: RegBank
  },
  {
    id: 'questions',
    hash: '#questions',
    title: 'registration_questions_title',
    description: 'registration_questions_description',
    Component: RegQuestions
  }
]
