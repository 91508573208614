import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import FieldWrap from './FieldWrap'
import { FieldLabel } from './Utils'

const FieldWrapGrid = styled(FieldWrap)`
  align-items: ${props => props.alignItems};
  display: grid;
  grid-gap: 16px;
  grid-template-columns: ${props => `repeat(${props.columnCount}, 1fr)`};
  & ${FieldWrap} {
    margin-bottom: 0 !important;
  }
`

const FlexFieldWrap = props => {
  const { children, label, labelHint, ...restProps } = props

  if (label) {
    return (
      <Fragment>
        <FieldLabel hint={labelHint}>{label}</FieldLabel>
        <FieldWrapGrid {...restProps}>
          {children}
        </FieldWrapGrid>
      </Fragment>
    )
  }

  return (
    <FieldWrapGrid {...restProps}>
      {children}
    </FieldWrapGrid>
  )
}

FlexFieldWrap.propTypes = {
  alignItems: PropTypes.string,
  columnCount: PropTypes.number,
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  labelHint: PropTypes.string
}

FlexFieldWrap.defaultProps = {
  alignItems: 'baseline',
  columnCount: 2
}

export default FlexFieldWrap
