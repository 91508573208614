import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { useTranslate } from 'utils/translate'
import ChevronDown from 'icons/ChevronDown'
import { FieldLabel, FieldWrap } from 'components/FormComponents'
import { SecondaryButton } from 'components/Buttons'
import useEImzo from 'components/EImzoDialog/hooks/useEImzo'

const SelectWrapper = styled.div`
  position: relative;
`

const Select = styled.select`
  appearance: none;
  background-color: ${props => props.theme.palette.input.background};
  border-radius: ${props => props.theme.shape.borderRadius};
  border-color: transparent;
  color: inherit;
  display: block;
  height: 40px;
  padding: 0 40px 0 18px;
  outline: none;
  transition: ${props => props.theme.transition};
  width: 100%;

  & + svg {
    fill: ${props => props.theme.palette.input.placeholder};
    position: absolute;
    top: 10px;
    right: 12px;
    transition: ${props => props.theme.transition};
  }

  &:hover {
    background-color: ${props => props.theme.palette.input.backgroundHover};
  }

  &:focus {
    background-color: ${props => props.theme.palette.common.white};
    border-color: ${props => props.theme.palette.primary.default};
    & + svg {
      fill: ${props => props.theme.palette.primary.default};
      transform: rotate(180deg);
    }
  }
`

const MessageLabel = styled.label`
  color: ${props => props.theme.palette.primary.default};
  display: block;
  margin-bottom: 15px;

  &:empty {
    display: none;
  }
`

const ErrorMessageLabel = styled(MessageLabel)`
  color: ${props => props.theme.palette.error};
`

const EImzoForm = props => {
  const { text, onSubmit } = props

  const { t } = useTranslate()
  const formRef = useRef()

  const onSuccess = (...data) => {
    onSubmit(...data)
  }

  const imzoParams = {
    formRef,
    text,
    onSuccess
  }

  const {
    isLoading,
    errorMessage,
    onChangeSelect,
    onSign
  } = useEImzo(imzoParams)

  return (
    <form ref={formRef}>
      {isLoading
        ? <MessageLabel>{t('common_loading_message')}</MessageLabel>
        : <ErrorMessageLabel>{errorMessage}</ErrorMessageLabel>}

      <FieldWrap>
        <FieldLabel>{t('eimzo_select_key')}</FieldLabel>
        <SelectWrapper>
          <Select name="key" onChange={onChangeSelect} />
          <ChevronDown />
        </SelectWrapper>
      </FieldWrap>

      <FieldWrap>
        <SecondaryButton onClick={onSign} type="button" fullWidth={true}>
          {t('eimzo_button_sign')}
        </SecondaryButton>
      </FieldWrap>
    </form>
  )
}

EImzoForm.propTypes = {
  text: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default EImzoForm
