import React, { Fragment, useEffect } from 'react'
import { prop } from 'ramda'
import { Controller, useFormContext } from 'react-hook-form'

import sectionPropTypes from './sectionPropTypes'
import Section from './Section'

import * as API from 'constants/api'
import { EMAIL_PATTERN_VALIDATE, NUMBER_PATTERN_VALIDATE } from 'constants/fieldPatterns'
import { validateLength } from 'utils/validations'
import { useTranslate } from 'utils/translate'
import Title from 'components/Title'
import {
  FieldWrap,
  FieldWrapFlex,
  SearchFieldControl,
  PhoneInput,
  Input,
  Checkbox
} from 'components/FormComponents'
import { Divider } from 'components/UI'

const RegContacts = props => {
  const { id, title, description } = props

  const { t } = useTranslate()
  const {
    register,
    control,
    watch,
    setValue
  } = useFormContext()

  const legalRegion = watch('address.legal.region')
  const legalRegionId = prop('id', legalRegion)

  const legalIsPhysical = watch('legalIsPhysical')

  const physicalRegion = watch('address.actual.region')
  const physicalRegionId = prop('id', physicalRegion)

  useEffect(() => {
    if (legalIsPhysical) {
      setValue('address.actual', undefined)
    }
  }, [legalIsPhysical])

  const email = watch('email')

  return (
    <Section id={id}>
      <Title description={t(description)}>
        {t(title)}
      </Title>

      <FieldWrap>
        <PhoneInput
          name={'phoneNumber'}
          control={control}
          label={t('registration_common_phone_input_label')}
          placeholder={t('registration_common_phone_input_placeholder')}
        />
      </FieldWrap>
      <FieldWrap>
        <Input
          ref={register()}
          name={'webSite'}
          label={t('registration_contacts_website_input_label')}
          placeholder={t('registration_contacts_website_input_placeholder')}
        />
      </FieldWrap>
      <FieldWrap>
        <Input
          ref={register({
            required: true,
            pattern: EMAIL_PATTERN_VALIDATE
          })}
          name={'email'}
          label={t('registration_common_email_input_label')}
          placeholder={t('registration_common_email_input_placeholder')}
        />
      </FieldWrap>
      <FieldWrap>
        <Input
          ref={register({
            required: true,
            pattern: EMAIL_PATTERN_VALIDATE,
            validate: value => {
              if (value && value !== email) {
                return t('validate_email_confirm')
              }
            }
          })}
          name={'emailConfirm'}
          label={t('registration_contacts_email_repeat_label')}
          placeholder={t('registration_common_email_input_placeholder')}
        />
      </FieldWrap>

      <Divider />

      <FieldWrapFlex label={t('registration_contacts_legal_address_label')}>
        <FieldWrap>
          <SearchFieldControl
            name={'address.legal.region'}
            rules={{ required: true }}
            control={control}
            placeholder={t('registration_contacts_address_region')}
            api={API.REGIONS_LIST}
            params={{ ordering: 'name' }}
          />
        </FieldWrap>
        <FieldWrap>
          <SearchFieldControl
            name="address.legal.district"
            rules={{ required: true }}
            control={control}
            placeholder={t('registration_contacts_address_district')}
            api={API.DISTRICTS_LIST}
            params={{ region: legalRegionId }}
            parent={legalRegionId}
            isDisabled={!legalRegionId}
          />
        </FieldWrap>
        <FieldWrap>
          <Input
            ref={register({ required: true })}
            name={'address.legal.street'}
            placeholder={t('registration_contacts_address_street')}
          />
        </FieldWrap>
        <FieldWrap>
          <Input
            ref={register({ required: true })}
            name={'address.legal.houseNumber'}
            placeholder={t('registration_contacts_address_house')}
          />
        </FieldWrap>
        <FieldWrap>
          <Input
            ref={register({
              required: true,
              pattern: NUMBER_PATTERN_VALIDATE,
              validate: validateLength(6)
            })}
            name={'address.legal.postcode'}
            placeholder={t('registration_contacts_address_postcode')}
            maxLength={6}
          />
        </FieldWrap>
      </FieldWrapFlex>
      <FieldWrap>
        <Controller
          name={'legalIsPhysical'}
          control={control}
          defaultValue={false}
          render={({ ref, ...controller }) => (
            <Checkbox
              label={t('registration_contacts_legal_is_physical')}
              {...controller}
            />
          )}
        />
      </FieldWrap>

      {!legalIsPhysical && (
        <Fragment>
          <Divider />
          <FieldWrapFlex
            label={t('registration_contacts_physical_address_label')}
          >
            <FieldWrap>
              <SearchFieldControl
                rules={{ required: !legalIsPhysical }}
                name="address.actual.region"
                control={control}
                placeholder={t('registration_contacts_address_region')}
                api={API.REGIONS_LIST}
                params={{ ordering: 'name' }}
              />
            </FieldWrap>
            <FieldWrap>
              <SearchFieldControl
                rules={{ required: !legalIsPhysical }}
                name="address.actual.district"
                control={control}
                placeholder={t('registration_contacts_address_district')}
                api={API.DISTRICTS_LIST}
                params={{ region: physicalRegionId }}
                parent={physicalRegionId}
                isDisabled={!physicalRegionId}
              />
            </FieldWrap>
            <FieldWrap>
              <Input
                ref={register({ required: !legalIsPhysical })}
                name={'address.actual.street'}
                placeholder={t('registration_contacts_address_street')}
              />
            </FieldWrap>
            <FieldWrap>
              <Input
                ref={register({ required: !legalIsPhysical })}
                name={'address.actual.houseNumber'}
                placeholder={t('registration_contacts_address_house')}
              />
            </FieldWrap>
            <FieldWrap>
              <Input
                ref={register({
                  required: !legalIsPhysical,
                  pattern: NUMBER_PATTERN_VALIDATE,
                  validate: validateLength(6)
                })}
                name={'address.actual.postcode'}
                placeholder={t('registration_contacts_address_postcode')}
                maxLength={6}
              />
            </FieldWrap>
          </FieldWrapFlex>
        </Fragment>
      )}
    </Section>
  )
}

RegContacts.propTypes = sectionPropTypes

export default RegContacts
