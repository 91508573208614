import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { SwitchContainer, SwitchInput, CheckMark } from 'components/Switches'

const RadioCheckMark = styled(CheckMark)`
  border-radius: 50%;
  &:after {
    background: ${props => props.theme.palette.primary.default};
    border-radius: 50%;
    transform: scale(0);
    top: 6px;
    left: 6px;
    height: 10px;
    width: 10px;
`

const StyledInput = styled(SwitchInput)`
  &:checked + ${RadioCheckMark} {
    background-color: ${props => props.theme.palette.common.white};
    &:after {
      transform: scale(1);
    }
  }
`

const RadioButton = props => {
  const { label, ...restProps } = props
  const { disabled, defaultChecked } = restProps

  return (
    <SwitchContainer data-checked={defaultChecked} disabled={disabled}>
      {label}
      <StyledInput {...restProps} type={'radio'} />
      <RadioCheckMark />
    </SwitchContainer>
  )
}

RadioButton.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  disabled: PropTypes.bool
}

export default RadioButton
