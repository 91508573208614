import React from 'react'
import AdmissinContract from 'routes/admissin-contract/components/AdmissinContract'
import { AdmissionContractProvider } from 'contexts/AdmissinContractProvider'

const AdmissinContractContainer = () => {
  return (
    <AdmissionContractProvider>
      <AdmissinContract />
    </AdmissionContractProvider>
  )
}

export default AdmissinContractContainer
