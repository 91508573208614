import React from 'react'
import sections from '../sections'

export default () => (
  <div>
    {sections.map(section => {
      const { Component, ...rest } = section

      return (
        <Component key={rest.id} {...rest} />
      )
    })}
  </div>
)
