import PropTypes from 'prop-types'
import styled from 'styled-components'

const Divider = styled.div`
  border-bottom: 1px solid ${props => props.theme.palette.toggle.shapeColor};
  height: 0;
  margin-top: ${props => props.margin};
  margin-bottom: ${props => props.margin};
  width: 100%;
`

Divider.propTypes = {
  margin: PropTypes.string
}

Divider.defaultProps = {
  margin: '26px'
}

export default Divider
