import React, { Fragment } from 'react'
import routes from '../../routes'
import RouteWithSubRoutes from './RouteWithSubRoutes'

export default () => (
  <Fragment>
    {routes.map((route, key) => (
      <RouteWithSubRoutes key={key} {...route} />
    ))}
  </Fragment>
)
