import * as ROUTES from 'constants/routes'
import { BaseLayout } from 'components/Layouts'
// import { SignInContainer } from './sign-in/containers'
import { RegistrationContainer } from './registration/containers'
import { AdmissinContractContainer } from './admissin-contract/containers'
import EImzo from './eimzo/EImzo'

export default [
  {
    exact: true,
    path: ROUTES.ROOT_PATH,
    layout: BaseLayout,
    component: RegistrationContainer
  },
  {
    exact: true,
    path: ROUTES.ADMISSIN_CONTRACT_PATH,
    layout: BaseLayout,
    component: AdmissinContractContainer
  },
  // {
  //   path: ROUTES.SIGN_IN_PATH,
  //   layout: BlankLayout,
  //   component: SignInContainer
  // },
  {
    path: '/e-imzo',
    layout: BaseLayout,
    component: EImzo
  }
]
