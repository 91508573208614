import React, { Fragment } from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { ErrorProvider } from './ErrorDialog'
import { ImzoProvider } from './EImzoDialog'
import AppLayout from './Layouts/AppLayout'
import { NormalizeStyles, GlobalStyles } from './Utils'
import Routes from './Routes'

import theme from 'constants/theme'
import { LocaleProvider } from 'contexts/LocaleProvider'

export default () => {
  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <NormalizeStyles />
        <GlobalStyles />

        <LocaleProvider>
          <ErrorProvider>
            <ImzoProvider>
              <Router>
                <AppLayout>
                  <Switch>
                    <Routes />
                  </Switch>
                </AppLayout>
              </Router>
            </ImzoProvider>
          </ErrorProvider>
        </LocaleProvider>
      </Fragment>
    </ThemeProvider>
  )
}
