import { path } from 'ramda'
import { sprintf } from 'sprintf-js'
import locales from 'constants/locales'
import { useLocale } from 'contexts/LocaleProvider'

export const t = (key, locale) => {
  return path([key, locale], locales) || path([key, 'ru'], locales)
}

export const useTranslate = () => {
  const { locale } = useLocale()

  const translate = (key, params) => {
    return sprintf(t(key, locale), params)
  }

  return { t: translate }
}
