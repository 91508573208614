import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
// import { useHistory } from 'react-router-dom'
import { getToken } from 'constants/token'
// import { SIGN_IN_PATH } from 'constants/routes'
import AuthContext from './AuthContext'

const AuthWrapper = ({ children }) => {
  // const history = useHistory()
  const token = getToken()

  useEffect(() => {
    // if (!token) {
    //   history.replace(SIGN_IN_PATH)
    // }
  }, [])

  return (
    <AuthContext.Provider value={{ token, isAuth: !!token }}>
      {children}
    </AuthContext.Provider>
  )
}

AuthWrapper.propTypes = {
  children: PropTypes.node.isRequired
}

export default AuthWrapper
