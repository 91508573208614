import styled from 'styled-components'
import buttonPropTypes from './buttonPropTypes'
import BaseButton from './BaseButton'

const PrimaryButton = styled(BaseButton)`
  background: ${props => props.theme.palette.primary.default};
  color: ${props => props.theme.palette.common.white};

  &:focus {
    background: ${props => props.theme.palette.primary.lighter};
  }

  &:hover {
    background: ${props => props.theme.palette.primary.darker};
  }

  &:active {
    background: ${props => props.theme.palette.primary.default};
  }
`

PrimaryButton.propTypes = buttonPropTypes

export default PrimaryButton
