import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import RegAnchors from './RegAnchors'
import RegMainFields from './RegMainFields'
import { useTranslate } from 'utils/translate'
import { useReg } from 'contexts/RegistrationProvider'
import { Form } from 'components/FormComponents'
import { CancelButton, SecondaryButton } from 'components/Buttons'
import Container from 'components/Container'

const Wrapper = styled.div`
  display: flex;
`

const FormWrapper = styled.div`
  margin-left: 50px;
  max-width: 700px;
`

const ButtonGroup = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(2, 1fr);
`

const RegMain = props => {
  const { toPrevStep, toNextStep } = props

  const { t } = useTranslate()

  const { state, dispatch } = useReg()

  const onSubmit = values => {
    dispatch(values)
    toNextStep()
  }

  return (
    <Fragment>
      <Container>
        <Wrapper>
          <RegAnchors />

          <FormWrapper>
            <Form mode={'onBlur'} onSubmit={onSubmit} defaultValues={state}>
              <Fragment>
                <RegMainFields />

                <ButtonGroup>
                  <CancelButton type={'button'} onClick={toPrevStep}>
                    {t('common_button_back')}
                  </CancelButton>
                  <SecondaryButton>
                    {t('common_button_next')}
                  </SecondaryButton>
                </ButtonGroup>
              </Fragment>
            </Form>
          </FormWrapper>
        </Wrapper>
      </Container>
    </Fragment>
  )
}

RegMain.propTypes = {
  toPrevStep: PropTypes.func.isRequired,
  toNextStep: PropTypes.func.isRequired,
}

export default RegMain
