import styled from 'styled-components'

export default styled.button`
  align-items: center;
  border-radius: ${props => props.theme.shape.borderRadius};
  border: none;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  font-size: inherit;
  font-weight: 500;
  height: ${props => props.theme.shape.button.height};
  padding: 0 80px;
  transition: ${props => props.theme.transition};
  width: ${props => props.fullWidth && '100%'};

  &:focus {
    outline: 0;
  }

  &:disabled {
    opacity: 0.7;
    pointer-events: none !important;
  }
`
