import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import RegistrationBox from './RegistrationBox'

import { useTranslate } from 'utils/translate'
import { useReg } from 'contexts/RegistrationProvider'
import Title from 'components/Title'
import { FieldWrap, Form, Input, PhoneInput } from 'components/FormComponents'
import { SecondaryButton } from 'components/Buttons'

const RegPersonal = props => {
  const { toNextStep } = props

  const { t } = useTranslate()
  const { state, dispatch } = useReg()

  const onSubmit = values => {
    dispatch({
      ...state,
      staff: {
        ...state.staff,
        personInCharge: {
          ...state.staff.personInCharge,
          ...values.staff.personInCharge
        }
      }
    })
    toNextStep()
  }

  return (
    <RegistrationBox>
      <Title description={t('registration_personal_description')}>
        {t('registration_title')}
      </Title>

      <Form mode={'onBlur'} onSubmit={onSubmit} defaultValues={state}>
        {({ register, control }) => (
          <Fragment>
            <FieldWrap>
              <Input
                ref={register({ required: true })}
                name={'staff.personInCharge.name'}
                label={t('registration_personal_input_label')}
                placeholder={t('registration_personal_input_placeholder')}
              />
            </FieldWrap>
            <FieldWrap>
              <PhoneInput
                name={'staff.personInCharge.phone'}
                control={control}
                rules={{ required: true }}
                label={t('registration_common_phone_input_label')}
                placeholder={t('registration_common_phone_input_placeholder')}
              />
            </FieldWrap>

            <SecondaryButton fullWidth={true}>
              {t('common_button_next')}
            </SecondaryButton>
          </Fragment>
        )}
      </Form>
    </RegistrationBox>
  )
}

RegPersonal.propTypes = {
  toNextStep: PropTypes.func.isRequired,
}

export default RegPersonal
