import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const SText = styled.div`
  text-align: ${props => props.align};
  font-weight: ${props => props.fontWeight};
  text-transform: ${props => props.transform};
`

const Text = props => {
  return (
    <SText {...props} />
  )
}

Text.propTypes = {
  align: PropTypes.oneOf([
    'initial',
    'center',
    'right'
  ]),
  fontWeight: PropTypes.number,
  transform: PropTypes.oneOf([
    'uppercase',
    'lowercase'
  ])
}

export default Text
