export const EMAIL = /^\S+@\S+$/
export const CHAR = /[A-Za-z]/
export const NUMBER = /^\d+$/
export const ONLY_LATIN = /^[a-z0-9()[\]!@#$%^&*/:;'",.{}+_ ]+$/i
export const COMPANY_NAME = /^[A-Za-zА-Яа-я0-9\s\-_'\\`]+$/

export const NUMBER_PATTERN_VALIDATE = {
  value: NUMBER,
  message: 'Введите только числовые значения'
}

export const EMAIL_PATTERN_VALIDATE = {
  value: EMAIL,
  message: 'Неверный формат email'
}
