import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { path, prop } from 'ramda'
import { useCreate, useDetail, useEImzoSign } from 'hooks'
import { sprintf } from 'sprintf-js'
import * as API from 'constants/api'
import { useErrorDialog } from 'components/ErrorDialog'
import { useImzoDialog } from 'components/EImzoDialog'
import toSnakeCase from 'utils/toSnakeCase'
import { Button } from './components'
import DocsAgreement from './DocsAgreement'
import DocsContract from './DocsContract'

const AGREEMENT = 'contract_agreement'
const CONTRACT = 'contract'

const Docs = props => {
  const {
    company,
    kind,
    toNextStep,
    contractNumber: contractNumberFromProps = ''
  } = props

  const companyId = prop('id', company)
  const contractNumberFromCompany = prop('contractNumber', company)
  const contractNumber = contractNumberFromProps || contractNumberFromCompany
  const businessTypeId = path(['businessType', 'id'], company)

  const agreement = useDetail(sprintf(API.AGREEMENT_PDF_DETAIL, companyId), null, false)
  const contract = useDetail(sprintf(API.CONTRACT_PDF_DETAIL, businessTypeId), null, false)

  useEffect(() => {
    if (kind === 'contract_agreement') {
      agreement.getDetail()
    }

    if (kind === 'contract') {
      contract.getDetail()
    }
  }, [kind])

  const errorDialog = useErrorDialog()
  const imzoDialog = useImzoDialog()
  const { getAgreementText, getContractText } = useEImzoSign({ company })

  const signCreate = useCreate(sprintf(API.SIGN_CONTRACT_POST, companyId))

  const signTextByKind = {
    [AGREEMENT]: getAgreementText(agreement),
    [CONTRACT]: getContractText(contract)
  }

  const onSign = () => {
    const signText = signTextByKind[kind]

    imzoDialog({
      text: signText,
      onSuccess: key => {
        const dataByKind = {
          [AGREEMENT]: { kind, sign: key, contractNumber },
          [CONTRACT]: { kind, sign: key, contractNumber }
        }
        const data = toSnakeCase(dataByKind[kind])
        return signCreate.create(data)
          .then(() => toNextStep())
          .catch(error => {
            errorDialog({ message: error })
          })
      }
    })
  }

  return (
    <Fragment>
      {kind === 'contract_agreement' && (
        <DocsAgreement
          data={agreement}
          company={company}
        />
      )}

      {kind === 'contract' && (
        <DocsContract
          data={contract}
          company={company}
          contractNumber={contractNumber}
        />
      )}

      <Button onSign={onSign} />
    </Fragment>
  )
}

Docs.propTypes = {
  kind: PropTypes.oneOf(['contract_agreement', 'contract']).isRequired,
  company: PropTypes.object.isRequired,
  contractNumber: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  toPrevStep: PropTypes.func,
  toNextStep: PropTypes.func.isRequired
}

export default Docs
