import React, { useCallback, useState } from 'react'
import { useAdmissionContract } from 'contexts/AdmissinContractProvider'
import AdmissinContractTIN from './AdmissinContractTIN'
import Docs from 'components/Docs'
import ThankYou from 'components/ThankYou/ThankYou'
import useQuery from 'hooks/useQuery'

const scrollToTop = () => {
  if (typeof window !== 'undefined') {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
}

const AdmissinContract = () => {
  const [step, setStep] = useState(0)

  const { state: company } = useAdmissionContract()

  const query = useQuery()

  const contractNumber = query.get('contractNumber')

  const toNextStep = useCallback(() => {
    scrollToTop()
    setStep(prevStep => prevStep + 1)
  }, [])

  const toPrevStep = useCallback(() => {
    scrollToTop()
    setStep(prevStep => prevStep - 1)
  }, [])

  const stepComponents = [(
    <AdmissinContractTIN
      key={'tin'}
      toNextStep={toNextStep}
    />
  ), (
    <Docs
      key={'doc_agreement'}
      kind={'contract_agreement'}
      company={company}
      contractNumber={contractNumber}
      toPrevStep={toPrevStep}
      toNextStep={toNextStep}
    />
  ), (
    <Docs
      key={'doc_contract'}
      kind={'contract'}
      company={company}
      contractNumber={contractNumber}
      toPrevStep={toPrevStep}
      toNextStep={toNextStep}
    />
  ), (
    <ThankYou key={'thanks'} />
  )]

  return stepComponents[step]
}

export default AdmissinContract
