import styled from 'styled-components'

export default styled.span`
  background: white;
  border-radius: ${props => props.theme.shape.checkbox.borderRadius};
  border: 2px solid ${props => props.theme.palette.toggle.shapeColor};
  position: absolute;
  transition: ${props => props.theme.transition};
  top: 0;
  left: 0;
  height: ${props => props.theme.shape.checkbox.size};
  width: ${props => props.theme.shape.checkbox.size};

  &:after {
    content: '';
    opacity: 0;
    position: absolute;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  }
`
