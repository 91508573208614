import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { SwitchContainer } from 'components/Switches'

const Fieldset = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
  & ${SwitchContainer} {
    position: relative;
    width: 100%;
    z-index: 0;
    &:after {
      border-radius: ${props => props.theme.shape.borderRadius};
      content: '';
      position: absolute;
      transition: ${props => props.theme.transition};
      top: -8px;
      left: -26px;
      right: 0;
      bottom: -8px;
      z-index: -1;
    }

    &:not([data-checked="true"]):hover:after {
      background-color: ${props => props.theme.palette.toggle.hoverBackground};
    }

    &[data-checked="true"]:after {
      background-color: ${props => props.theme.palette.toggle.activeBackground};
    }
  }
`

const RadioGroup = props => {
  const { children, onChange, ...restProps } = props
  const { name, value } = restProps

  const onClick = event => onChange && onChange(event.target.value, event)

  return (
    <Fieldset onChange={onClick}>
      {React.Children.map(children, (child, key) => {
        const checked = child.props.value === value
        return (
          <child.type
            key={key}
            name={name}
            defaultChecked={checked}
            {...child.props}
          />
        )
      })}
    </Fieldset>
  )
}

RadioGroup.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default RadioGroup
