import styled from 'styled-components'
import buttonPropTypes from './buttonPropTypes'
import BaseButton from './BaseButton'

const CancelButton = styled(BaseButton)`
  background: ${props => props.theme.palette.cancel.default};
  color: ${props => props.theme.palette.text.primary};

  &:focus {
    background: ${props => props.theme.palette.cancel.lighter};
  }

  &:hover {
    background: ${props => props.theme.palette.cancel.darker};
  }

  &:active {
    background: ${props => props.theme.palette.cancel.default};
  }
`

CancelButton.propTypes = buttonPropTypes

CancelButton.defaultProps = {
  type: 'button'
}

export default CancelButton
