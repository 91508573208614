import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { searchFieldPropTypes } from '../SearchFields/utils'
import { SearchField } from '../SearchFields'

const SearchFieldControl = props => {
  const {
    name,
    control,
    rules,
    onChange,
    ...restProps
  } = props

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={''}
      rules={rules}
      render={controller => (
        <SearchField
          {...restProps}
          {...controller}
          onChange={value => {
            if (typeof onChange === 'function') {
              onChange(value, controller)
            } else {
              controller.onChange(value)
            }
          }}
        />
      )}
    />
  )
}

SearchFieldControl.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  onChange: PropTypes.func,
  ...searchFieldPropTypes
}

export default SearchFieldControl
