import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const TitleWrap = styled.div`
  margin-bottom: 26px;
`

const H1 = styled.h2`
  font-size: 2.15em;
  font-weight: 500;
  margin: 0 0 15px;
`

const Description = styled.div`
  color: ${props => props.theme.palette.text.secondary};
  font-size: 1.25em;
`

const Title = props => {
  const { children, description, ...restProps } = props

  if (description) {
    return (
      <TitleWrap {...restProps}>
        <H1>{children}</H1>
        <Description>{description}</Description>
      </TitleWrap>
    )
  }

  return (
    <H1 {...restProps}>{children}</H1>
  )
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.string
}

export default Title
