import hexToRgba from 'utils/hexToRgba'

const primaryColor = '#f0643d'
const textColor = '#2e384c'

export default {
  palette: {
    common: {
      black: '#000',
      white: '#fff'
    },
    primary: {
      default: primaryColor,
      lighter: '#f27755',
      darker: '#ee5125'
    },
    accent: {
      default: '#003a74'
    },
    secondary: {
      default: '#2e384c',
      lighter: '#38445c',
      darker: '#242c3c'
    },
    cancel: {
      default: '#eaeaee',
      lighter: '#f8f8f9',
      darker: '#dcdce3'
    },
    text: {
      primary: textColor,
      secondary: '#9e9e9e',
      opacity: hexToRgba(textColor, '0.8')
    },
    input: {
      border: '#e4e5e7',
      background: '#f6f6f8',
      backgroundHover: '#e8e8ec',
      backgroundError: '#fef1f1',
      label: textColor,
      placeholder: '#9e9e9e'
    },
    toggle: {
      activeBackground: '#fef3f0',
      hoverBackground: '#f6f6f8',
      shapeColor: '#b6b9be'
    },
    error: '#f51010',
    warning: ''
  },
  shape: {
    borderRadius: '10px',
    button: {
      height: '66px'
    },
    checkbox: {
      borderRadius: '8px',
      size: '26px'
    },
    input: {
      height: '66px',
      padding: '0 18px'
    },
    table: {
      borderWidth: '2px'
    },
    textarea: {
      minHeight: '98px',
      padding: '18px'
    },
    select: {
      height: '66px',
      menu: {
        boxShadow: '3px 6px 20px rgba(0, 0, 0, 0.06)',
        padding: '10px'
      },
      option: {
        borderRadius: '8px',
        padding: '10px 8px'
      }
    }
  },
  transition: 'cubic-bezier(0.0, 0, 0.2, 1) 300ms'
}
