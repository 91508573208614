export const ROOT_PATH = '/'

const SIGN_IN = 'sign-in'
export const SIGN_IN_PATH = `/${SIGN_IN}`

const REGISTRATION = 'registration'
export const REGISTRATION_PATH = `/${REGISTRATION}`

const ADMISSIN_CONTRACT = 'admissin-contract'
export const ADMISSIN_CONTRACT_PATH = `/${ADMISSIN_CONTRACT}`
