import React, { forwardRef } from 'react'
import SearchFieldBase from './SearchFieldBase'
import {
  getStaticOption,
  getStaticOptions,
  defaultGetText,
  defaultGetValue,
  searchFieldStaticPropTypes
} from './utils'

const SearchFieldStatic = forwardRef((props, ref) => {
  const { list, itemText } = props

  return (
    <SearchFieldBase
      inputRef={ref}
      getText={defaultGetText(itemText)}
      getValue={defaultGetValue(['id'])}
      getOptions={search => getStaticOptions(search, list)}
      getOption={id => getStaticOption(id, list)}
      isStatic={true}
      isClearable={true}
      {...props}
    />
  )
})

SearchFieldStatic.propTypes = {
  ...searchFieldStaticPropTypes
}

SearchFieldStatic.defaultProps = {
  itemText: ['name']
}

export default SearchFieldStatic
