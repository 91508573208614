import React from 'react'
import PropTypes from 'prop-types'
import { FormProvider, useForm } from 'react-hook-form'

const Form = props => {
  const {
    defaultValues,
    children,
    onSubmit,
    mode
  } = props

  const methods = useForm({
    mode,
    reValidateMode: 'onBlur',
    defaultValues
  })
  const { handleSubmit } = methods

  const onSubmitForm = values => onSubmit(values, methods)

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        {typeof children === 'function'
          ? children(methods)
          : React.cloneElement(children)}
      </form>
    </FormProvider>
  )
}

Form.propTypes = {
  defaultValues: PropTypes.object,
  children: PropTypes.any.isRequired,
  onSubmit: PropTypes.func.isRequired,
  mode: PropTypes.oneOf([
    'all',
    'onBlur',
    'onSubmit',
    'onChange',
    'onTouched'
  ])
}

export default Form
