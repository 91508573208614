import React from 'react'
import PropTypes from 'prop-types'
import { path, prop } from 'ramda'
import dayjs from 'dayjs'
import { Text } from 'components/UI'
import {
  Wrap,
  Table,
  TData,
  TRow,
  TRowHalf
} from './components'

const DocsAgreement = props => {
  const { data, company } = props

  const today = new Date()
  const date = dayjs(today).format('DD.MM.YYYY')

  const isLoading = prop('isLoading', data)
  const agreementData = path(['detail', 'data'], data) || []
  const directorFio = path(['staff', 'head', 'name'], company)

  if (isLoading) {
    return (
      <div>Loading agreement...</div>
    )
  }

  return (
    <Wrap>
      <Table cellPadding="0" cellSpacing="0">
        <tbody>
          <TRowHalf>
            <TData padding={'30px'}>
              <Text align={'center'} fontWeight={500}>GS1 Uzbekistan Uyushmasi a'zosining Bitimi</Text>
            </TData>
            <TData padding={'30px'}>
              <Text align={'center'} fontWeight={500}>Соглашение члена Ассоциации GS1 Uzbekistan</Text>
            </TData>
          </TRowHalf>
          {agreementData.map((item, index) => {
            const ordering = index + 1
            const uz = prop('uz', item)
            const ru = prop('ru', item)

            return (
              <TRowHalf key={index} border={false}>
                <TData>
                  {`${ordering}. ${uz}`}
                </TData>
                <TData>
                  {`${ordering}. ${ru}`}
                </TData>
              </TRowHalf>
            )
          })}
        </tbody>

        <tbody>
          <TRow>
            <TData colSpan="2">
              <Text className="margin-bottom-big">F.I.Sh./Ф.И.О {directorFio}</Text>
              <Text align={'right'} className="margin-bottom-big">Sana/Дата {date}</Text>
            </TData>
          </TRow>
        </tbody>
      </Table>
    </Wrap>
  )
}

DocsAgreement.propTypes = {
  data: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired
}

export default DocsAgreement
