import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Error = styled.div`
  border-radius: ${props => props.theme.shape.borderRadius};
  color: ${props => props.theme.palette.error};
  font-size: 0.875em;
  margin-top: 6px;
  text-align: right;
  &:empty {
    display: none;
  }
`

const InputError = props => (
  <Error dangerouslySetInnerHTML={{ __html: props.children }} />
)

InputError.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ])
}

export default InputError
