import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App'
import './styles/main.css'
import reportWebVitals from './reportWebVitals'

const renderNode = (
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

ReactDOM.render(renderNode, document.getElementById('root'))

reportWebVitals()
