import React from 'react'
import styled from 'styled-components'
import Title from 'components/Title'
import PropTypes from 'prop-types'
import { useTranslate } from 'utils/translate'

const ErrorWrap = styled.div`
  width: ${({ width }) => width || '540px'};
  padding: ${({ padding }) => padding || '100px 0'};
  margin: 0 auto;
`
const ErrorDescription = styled.div`
  font-size: 1.25em;
  margin-bottom: 50px;
  & span {
    color: ${props => props.theme.palette.primary.default};
  }
`

const Error = ({ title, description }) => {
  const { t } = useTranslate()

  const errorDescriptionDefault = t('registration_tin_error_description', {
    phone: process.env.REACT_APP_GS1_PHONE_NUMBER
  })

  return (
    <ErrorWrap>
      <Title>{title}</Title>
      <ErrorDescription
        dangerouslySetInnerHTML={{ __html: description || errorDescriptionDefault }}
      />
    </ErrorWrap>
  )
}

Error.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string
}

export default Error
