import React from 'react'
import { find, not, pipe, propEq } from 'ramda'
import styled from 'styled-components'

import { APP_LOCALES } from 'constants/locales'
import { useLocale } from 'contexts/LocaleProvider'

const Lang = styled.div`
  color: ${props => props.theme.palette.secondary.default};
  cursor: pointer;
  font-size: 15px;
  &:hover {
    text-decoration: underline;
  }
`

export default () => {
  const { locale, onChangeLocale } = useLocale()

  const outputLang = find(
    pipe(propEq('locale', locale), not),
    APP_LOCALES
  )

  return (
    <Lang onClick={onChangeLocale.bind(null, outputLang.locale)}>
      {outputLang.name}
    </Lang>
  )
}
