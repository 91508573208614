import React, { useState, useEffect } from 'react'
import { prop } from 'ramda'
import { useFormContext } from 'react-hook-form'

import sectionPropTypes from './sectionPropTypes'
import Section from './Section'

import * as API from 'constants/api'
import { NUMBER_PATTERN_VALIDATE } from 'constants/fieldPatterns'
import { useTranslate } from 'utils/translate'
import { validateLength } from 'utils/validations'
import request from 'utils/request'
import { useDebounce } from 'hooks'
import Title from 'components/Title'
import { FieldWrap, Input } from 'components/FormComponents'

const RegBank = props => {
  const { id, title, description } = props

  const { t } = useTranslate()

  const [bankCode, setBankCode] = useState(null)
  const [bankError, setBankError] = useState(null)
  const [bankLoading, setBankLoading] = useState(false)

  const { register, setValue, trigger } = useFormContext()

  const bankCodeDebounced = useDebounce(bankCode, 100)

  useEffect(() => {
    if (bankCodeDebounced) {
      setBankLoading(true)
      request.get(API.BANK_GET_CODE, { code: bankCodeDebounced })
        .then(({ data }) => {
          const bankName = prop('name', data)
          setValue('bankName', bankName, {
            shouldDirty: true,
            shouldValidate: true
          })
          trigger('bankName')
            .then(() => {
              setBankLoading(false)
              setBankError(false)
            })
        })
        .catch(() => {
          setValue('bankName', '')
          setBankLoading(false)
          setBankError(true)
        })
    }
  }, [bankCodeDebounced])

  const bankPlaceholder = bankError
    ? t('registration_bank_name_error_input_placeholder')
    : t('registration_bank_name_input_placeholder')

  return (
    <Section id={id}>
      <Title description={t(description)}>
        {t(title)}
      </Title>

      <FieldWrap>
        <Input
          ref={register({
            required: true,
            pattern: NUMBER_PATTERN_VALIDATE,
            validate: validateLength(5)
          })}
          name={'bankCode'}
          label={t('registration_bank_code_input_label')}
          placeholder={t('registration_bank_code_input_placeholder')}
          maxLength={5}
          onBlur={event => {
            setBankCode(event.target.value)
          }}
        />
      </FieldWrap>
      <FieldWrap>
        <Input
          ref={register({ required: true })}
          name={'bankName'}
          label={t('registration_bank_name_input_label')}
          labelHint={bankLoading ? t('registration_bank_name_loading_label') : ''}
          placeholder={bankPlaceholder}
          readOnly={true}
        />
      </FieldWrap>
      <FieldWrap>
        <Input
          ref={register({
            required: true,
            pattern: NUMBER_PATTERN_VALIDATE,
            validate: validateLength(20)
          })}
          name={'checkingAccount'}
          label={t('registration_bank_account_code_input_label')}
          placeholder={t('registration_bank_account_code_input_placeholder')}
          maxLength={20}
        />
      </FieldWrap>
    </Section>
  )
}

RegBank.propTypes = sectionPropTypes

export default RegBank
