export default (state, action) => {
  switch (action.type) {
    case 'open': {
      const { payload } = action
      return {
        ...state,
        ...payload,
        isOpen: true
      }
    }
    case 'close': {
      return {
        ...state,
        isOpen: false
      }
    }
    default:
      return state
  }
}
