import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import { TData, TListMain, TRowHalf } from '.'
import { Text } from 'components/UI'

const mapParagraphSubItems = (paragraph, index) => {
  const parentOrder = index + 1
  const title = prop('title', paragraph)
  const body = prop('body', paragraph) || []

  return (
    <TData>
      <Text fontWeight={500} transform={'uppercase'}>{parentOrder} {title}</Text>
      <TListMain>
        {body.map((sentence, bodyIndex) => {
          const bodyOrder = `${parentOrder}.${bodyIndex + 1}`
          return (
            <li key={bodyIndex}>{bodyOrder} {sentence}</li>
          )
        })}
      </TListMain>
    </TData>
  )
}

const ContractsParagraphs = props => {
  const { data } = props

  return (
    <>
      {data.map((item, index) => {
        const uz = prop('uz', item)
        const ru = prop('ru', item)

        return (
          <TRowHalf key={index} border={false}>
            {mapParagraphSubItems(uz, index)}
            {mapParagraphSubItems(ru, index)}
          </TRowHalf>
        )
      })}
    </>
  )
}

ContractsParagraphs.propTypes = {
  data: PropTypes.array.isRequired,
}

export default ContractsParagraphs
