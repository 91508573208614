import React, { useState, useCallback } from 'react'

import RegTIN from './RegTIN'
import RegPersonal from './RegPersonal'
import RegMain from './RegMain'
import RegPreview from './RegPreview'
// import RegDocs from './RegDocs'

import ThankYou from 'components/ThankYou/ThankYou'
import Docs from 'components/Docs'
import { useReg } from 'contexts/RegistrationProvider'

const scrollToTop = () => {
  if (typeof window !== 'undefined') {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
}

const Registration = () => {
  const { state: company } = useReg()

  const [step, setStep] = useState(0)

  const toNextStep = useCallback(() => {
    scrollToTop()
    setStep(prevStep => prevStep + 1)
  }, [])

  const toPrevStep = useCallback(() => {
    scrollToTop()
    setStep(prevStep => prevStep - 1)
  }, [])

  const toTINStep = useCallback(() => {
    setStep(0)
  }, [])

  const stepComponents = [
    /* 0 */(
      <RegTIN
        key={'tin'}
        toNextStep={toNextStep}
      />
    ),
    /* 1 */(
      <RegPersonal
        key={'personal'}
        toNextStep={toNextStep}
      />
    ),
    /* 2 */(
      <RegMain
        key={'main'}
        toPrevStep={toPrevStep}
        toNextStep={toNextStep}
      />
    ),
    /* 3 */(
      <RegPreview
        key={'preview'}
        toPrevStep={toPrevStep}
        toNextStep={toNextStep}
        toTINStep={toTINStep}
      />
    ),
    /* 4 */(
      <Docs
        key={'doc_agreement'}
        kind={'contract_agreement'}
        company={company}
        toPrevStep={toPrevStep}
        toNextStep={toNextStep}
      />
    ),
    /* 5 */(
      <Docs
        key={'doc_contract'}
        kind={'contract'}
        company={company}
        toPrevStep={toPrevStep}
        toNextStep={toNextStep}
      />
    ),
    /* 6 */(
      <ThankYou key={'thanks'} />
    )
  ]

  return stepComponents[step]
}

export default Registration
