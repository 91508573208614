import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Header from 'components/Header'
import Container from 'components/Container'
import Content from './Content'

const BaseLayout = props => {
  const { children } = props

  return (
    <Fragment>
      <Header />

      <Container>
        <Content>
          {children}
        </Content>
      </Container>
    </Fragment>
  )
}

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default BaseLayout
