import React from 'react'
import styled from 'styled-components'
import Container from 'components/Container'
import Logo from './Logo'
import Langs from './Langs'

const Wrapper = styled.div`
  border-bottom: 1px solid #dddfe1;
`

const Content = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 100px;
`

const Header = () => {
  return (
    <Wrapper>
      <Container>
        <Content>
          <Logo />
          <Langs />
        </Content>
      </Container>
    </Wrapper>
  )
}

Header.propTypes = {}

export default Header
