import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'

import {
  getLocale as getCookieLocale,
  setLocale as setCookieLocale
} from 'utils/cookies'

const LocaleContext = createContext(null)

export const LocaleProvider = props => {
  const { children } = props

  const cookieLocale = getCookieLocale()
  const [locale, setLocale] = useState(cookieLocale)

  const onChangeLocale = newLocale => {
    setCookieLocale(newLocale)
    setLocale(newLocale)
  }

  return (
    <LocaleContext.Provider value={{ locale, onChangeLocale }}>
      {children}
    </LocaleContext.Provider>
  )
}

LocaleProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const useLocale = () => {
  return useContext(LocaleContext)
}
