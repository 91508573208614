import { includes, path, prop, split } from 'ramda'
import { useFormContext } from 'react-hook-form'

import errorMessages from 'constants/errorMessages'
import { useTranslate } from 'utils/translate'

const formatInputName = name => {
  if (includes('.', name)) {
    return split('.', name)
  }
  return [name]
}

export default fieldName => {
  const { t } = useTranslate()
  const { errors } = useFormContext()

  const formedFieldName = formatInputName(fieldName)
  const errorData = path(formedFieldName, errors)
  const errorType = prop('type', errorData)
  const errorMessage = prop('message', errorData)

  return (errorType && !errorMessage)
    ? t(prop(errorType, errorMessages))
    : errorMessage
}
