import styled, { css } from 'styled-components'

export const TRow = styled.tr`
  ${props => props.border === false && (
    css`
      &:not(:last-of-type) td {
        border-bottom-color: transparent;
      }
    `
  )}
`

export const TRowHalf = styled(TRow)`
  & td {
    width: 50%;
  }
`
