export const validateLength = length => value => {
  if (value && value.length !== length) {
    return `Кол-во символов должно быть ${length}`
  }
}

export const validateCheckboxes = () => value => {
  if (!(Array.isArray(value) && value.length > 0)) {
    return 'Это поле обязательно'
  }
}
