import { path, prop, propOr, pathOr, trim } from 'ramda'
import dayjs from 'dayjs'

import PropTypes from 'prop-types'
import { formatContractNumber } from '../utils/transformData'

const useEImzoSign = props => {
  const { company } = props
  const emptyString = ' '
  const today = new Date()
  const currentDate = dayjs(today).format('DD.MM.YYYY')
  const currentYear = dayjs(today).format('YYYY')

  const tin = trim(prop('tin', company))
  const companyName = trim(prop('name', company))
  const ownership = trim(path(['ownership', 'name'], company))
  const ecoActivity = trim(path(['ecoActivity', 'name'], company))
  const businessType = trim(path(['businessType', 'name'], company))
  const bankCode = trim(prop('bankCode', company))
  const bankName = trim(prop('bankName', company))
  const checkingAccount = trim(prop('checkingAccount', company))

  const companyNameFull = trim(`${ownership} ${companyName}`)
  const contractNumber = prop('contractNumber', company)

  const staff = prop('staff', company)
  const directorFio = trim(path(['head', 'name'], staff))
  const directorPhone = trim(path(['head', 'phone'], staff))
  const directorPosition = trim(path(['head', 'position'], staff))
  const directorEmail = trim(pathOr(emptyString, ['head', 'email'], staff))
  const responsibleFio = trim(path(['personInCharge', 'name'], staff))
  const responsiblePhone = trim(path(['personInCharge', 'phone'], staff))
  const responsiblePosition = trim(path(['personInCharge', 'position'], staff))
  const responsibleEmail = trim(pathOr(emptyString, ['personInCharge', 'email'], staff))
  const accountantFio = trim(path(['accountant', 'name'], staff))
  const accountantPhone = trim(path(['accountant', 'phone'], staff))
  const accountantEmail = trim(pathOr(emptyString, ['accountant', 'email'], staff))

  const address = prop('address', company)
  const legalIsPhysical = prop('legalIsPhysical', company)

  const legalPostcode = trim(path(['legal', 'postcode'], address))
  const legalStreet = trim(path(['legal', 'street'], address))
  const legalHouseNumber = trim(path(['legal', 'houseNumber'], address))
  const legalRegion = trim(path(['legal', 'region', 'name'], address))
  const legalDistrict = trim(path(['legal', 'district', 'name'], address))
  const fullAddress = `${legalPostcode}, ${legalRegion}, ${legalDistrict}, ${legalStreet}, ${legalHouseNumber}`

  const physicalPostcode = legalIsPhysical ? legalPostcode : trim(path(['actual', 'postcode'], address))
  const physicalStreet = legalIsPhysical ? legalStreet : trim(path(['actual', 'street'], address))
  const physicalHouseNumber = legalIsPhysical ? legalHouseNumber : trim(path(['actual', 'houseNumber'], address))
  const physicalRegion = legalIsPhysical ? legalRegion : trim(path(['actual', 'region', 'name'], address))
  const physicalDistrict = legalIsPhysical ? legalDistrict : trim(path(['actual', 'district', 'name'], address))

  const getCompanyCardText = () => {
    return `
      Название предприятия: ${companyName}
      Инн: ${tin}
      Форма собственности: ${ownership}
      Вид деятельности по ОКЭД: ${ecoActivity}
      Категория организации: ${businessType}
      Код банка: ${bankCode}
      Банковские реквизиты: ${checkingAccount}

      Руководитель: ${directorFio}
      Должность: ${directorPosition}
      Телефон руководителя: ${directorPhone}
      Почта руководителя: ${directorEmail}

      Ответственный по работе: ${responsibleFio}
      Должность: ${responsiblePosition}
      Телефон ответственного по работе: ${responsiblePhone}
      Почта ответственного по работе: ${responsibleEmail}

      Бухгалтер: ${accountantFio}
      Телефон бухгалтера: ${accountantPhone}
      Почта бухгалтера: ${accountantEmail}

      Почтовый индекс юридического адреса: ${legalPostcode}
      Улица юридического адреса: ${legalStreet}
      Номер дома юридического адреса: ${legalHouseNumber}
      Город юридического адреса: ${legalRegion}
      Район юридического адреса: ${legalDistrict}

      Почтовый индекс физического адреса: ${physicalPostcode}
      Улица физического адреса: ${physicalStreet}
      Номер дома физического адреса: ${physicalHouseNumber}
      Город физического адреса: ${physicalRegion}
      Район физического адреса: ${physicalDistrict}


      Korxona nomi: ${companyName}
      STIR: ${tin}
      Mulkiy shakli: ${ownership}
      IFUT: ${ecoActivity}
      Tadbirkorlik subyekti: ${businessType}
      Bank kodi: ${bankCode}
      Hisob raqami: ${checkingAccount}

      Rahbar: ${directorFio}
      Lavozimi: ${directorPosition}
      Rahbar telefefon raqami: ${directorPhone}
      Rahbar email: ${directorEmail}

      GS1 Uzbekistan bilan ishlash uchun mas'ul shaxs: ${responsibleFio}
      Lavozimi: ${responsiblePosition}
      mas'ul shaxsning telefon raqami: ${responsiblePhone}
      mas'ul shaxsnin Email: ${responsibleEmail}

      Hisobchi: ${accountantFio}
      Hisobchi telefon raqami: ${accountantPhone}
      Hisobchining Email: ${accountantEmail}

      Yuridik manzil pochta indeksi: ${legalPostcode}
      Yuridik manzil ko'chasi: ${legalStreet}
      Yuridik manzil uy raqami: ${legalHouseNumber}
      Yuridik manzil tumani: ${legalRegion}
      Yuridik manzil viloyat: ${legalDistrict}

      Amaldagi manzil pochta indeksi: ${physicalPostcode}
      Amaldagi manzil ko'chasi: ${physicalStreet}
      Amaldagi manzil uy raqami: ${physicalHouseNumber}
      Amaldagi manzil tumani: ${physicalRegion}
      Amaldagi manzil viloyat: ${physicalDistrict}
    `
  }

  const getAgreementText = (agreement) => {
    const agreementData = path(['detail', 'data'], agreement) || []

    const titleRu = 'Соглашение члена Ассоциации GS1 Uzbekistan'
    const titleUz = 'GS1 Uzbekistan Uyushmasi a\'zosining Bitimi'

    const agreementBodyRu = agreementData.map((item, index) =>
      `${index + 1}. ${prop('ru', item)}`
    ).join('\n')
    const agreementBodyUz = agreementData.map((item, index) =>
      `${index + 1}. ${prop('uz', item)}`
    ).join('\n')

    const footer =
      `F.I.Sh./Ф.И.О ${directorFio}\n` +
      `Sana/Дата ${currentDate}`

    return `${titleUz}\n\n` +
      `${agreementBodyUz}\n\n` +
      `${titleRu}\n\n` +
      `${agreementBodyRu}\n\n` +
      `${footer}`
  }

  /* eslint-disable max-len */
  const getContractText = (contract) => {
    const contractData = path(['detail', 'data'], contract) || []
    const formattedData = {
      ru: [{
        title: `ДОГОВОР №${formatContractNumber(contractNumber)} о членстве в Ассоциации GS1 Uzbekistan`,
        body: [
          `г. Ташкент ${currentYear}`,
          `Ассоциация GS1 Uzbekistan в лице Председателя Ассоциации Н. Сабирова, действующего на основании Устава, именуемая в дальнейшем Ассоциация, с одной стороны и ${companyNameFull} в лице ${directorFio} действующего на основании устава (устава, положения, и др.) именуемый в дальнейшем “Член Ассоциации”, с другой стороны, заключили настоящий договор о нижеследующем:`
        ]
      }],
      uz: [{
        title: `${formatContractNumber(contractNumber)} № SHARTNOMA Uzbekistan Uyushmasiga a’zolik to’g’risida`,
        body: [
          `Toshkent shahri ${currentYear}`,
          `GS1 Uzbekistan Uyushmasi nomidan Ustav asosida faoliyat yurituvchi Uyushma Raisi N. Sabirov keyingi o’rinlarda “Uyushma” deb yuritiladi bir tomondan va ${companyNameFull} ustav asosida faoliyat yurituvchi (ustav, nizomi yoki b.) ${directorFio} orqali keyingi o’rinlarda “Uyushma A’zosi” deb yuritiladi, boshqa tomondan, ushbu shartnomani quyidagilar haqida tuzdilar:`
        ]
      }]
    }

    contractData.forEach(item => {
      const ru = prop('ru', item)
      const uz = prop('uz', item)
      formattedData.ru = [...formattedData.ru, ru]
      formattedData.uz = [...formattedData.uz, uz]
    })

    const mapParagraph = (lang) => {
      const data = formattedData[lang]
      return data.map((item, index) => {
        const titleText = prop('title', item)
        const title = index >= 1 ? `${index} ${titleText}` : titleText
        const bodyArr = propOr([], 'body', item)
        const body = bodyArr.map((bItem, bIndex) => {
          if (index >= 1) {
            return `${index}.${bIndex + 1} ${bItem}\n`
          }
          return `${bItem}\n`
        }).join('')

        return `${title}\n\n` +
          `${body}\n`
      })
    }

    const contractUzText = mapParagraph('uz')
    const contractRuText = mapParagraph('ru')

    const footerGS1UZ = 'GS1 Uzbekistan Uyushmasi\n' +
      '100031, Toshkent sh., Afrosiyob ko\'chasi, 16\n' +
      'h/r 2020 8000 3039 7181 2001, "Mikrokreditbank"\n' +
      'Bank kodi 00433 STIR 202 719 734, IFUT 91110'
    const footerGS1RU = 'Ассоциация GS1 Uzbekistan\n' +
      '100031, г. Ташкент, ул. Афросиаб, 16\n' +
      'р/с 2020 8000 3039 7181 2001, "Микрокредитбанк"\n' +
      'Код банка 00433 ИНН 202 719 734, ОКЭД 91110'
    const footerGS1Member = 'Rais/Председатель\n' +
      'N. Sabirov'
    const footerMember = `${companyNameFull}\n` +
      `${fullAddress}\n` +
      `${checkingAccount} ${bankName}\n` +
      `Код банка: ${bankCode} ИНН: ${tin}`

    const footer = `${contractUzText.length} TOMONLARNING YURIDIK MANZILI / ЮРИДИЧЕСКИЕ АДРЕСА СТОРОН\n\n` +
      `${footerGS1UZ}\n\n` +
      `${footerGS1RU}\n\n` +
      `${footerMember}\n\n` +
      `${footerGS1Member}\n\n`

    return `${contractUzText.join('')}\n` +
      `${contractRuText.join('')}\n` +
      `${footer}`
  }

  return {
    getCompanyCardText,
    getAgreementText,
    getContractText
  }
}

useEImzoSign.propsTypes = {
  company: PropTypes.object.isRequired
}

export default useEImzoSign
