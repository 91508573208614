/* eslint-disable max-len  */

export const APP_LOCALES = [
  { locale: 'ru', name: 'Русский' },
  { locale: 'uz', name: 'O\'zbekcha' }
]
export const DEFAULT_LOCALE = 'ru'

export default {
  // COMMON
  common_button_next: {
    ru: 'Далее',
    uz: 'Keyingi'
  },
  common_button_back: {
    ru: 'Назад',
    uz: 'Qaytish'
  },
  common_button_close: {
    ru: 'Закрыть',
    uz: 'Yopish'
  },
  common_button_confirm_and_sign: {
    ru: 'Подтвердить и подписать',
    uz: 'Tasdiqlash va imzolash'
  },
  common_error: {
    ru: 'Ошибка',
    uz: 'Xato'
  },
  common_unnecessary: {
    ru: 'необязательно',
    uz: 'shart emas'
  },
  common_select_placeholder: {
    ru: 'Выберите из списка',
    uz: 'Ro\'yxatdan tanlang'
  },
  common_select_not_found_message: {
    ru: 'Не найдено...',
    uz: 'Topilmadi...'
  },
  common_loading_message: {
    ru: 'Загрузка...',
    uz: 'Yuklanmoqda...'
  },

  // E-Imzo
  eimzo_dialog_title: {
    ru: 'Подпишите электронной цифровой подписью',
    uz: 'Elektron raqamli imzo bilan imzolang'
  },
  eimzo_select_key: {
    ru: 'Выберите ключ',
    uz: 'Tanlang'
  },
  eimzo_button_sign: {
    ru: 'Подписать',
    uz: 'Imzolash'
  },

  // FIELD VALIDATION ERRORS
  validate_required_field: {
    ru: 'Это поле обязательно',
    uz: 'Ushbu qator to\'ldirilishi shart'
  },
  validate_latin_chars: {
    ru: 'Введите латинскими буквами',
    uz: 'Lotin xarflar bilan yozing'
  },
  validate_company_name_chars: {
    ru: 'Разрешены только буквы, цифры, пробелы, тире, нижние подчеркивания и апострофы',
    uz: 'Faqat harflar, raqamlar, bo\'shliqlar, tirnoqlar, pastki chizish va apostroflar ruxsat etilgan'
  },
  validate_error_year: {
    ru: 'Неверный формат года',
    uz: 'Yil formati noto\'g\'ri'
  },
  validate_email_confirm: {
    ru: 'Адреса не совпадают',
    uz: 'Manzillar mos emas'
  },

  // REG COMMON
  registration_title: {
    ru: 'Регистрация',
    uz: 'Ro\'yxatdan o\'tish'
  },
  registration_common_phone_input_label: {
    ru: 'Номер телефона',
    uz: 'Telefon raqami'
  },
  registration_common_phone_input_placeholder: {
    ru: 'Введите номер телефона',
    uz: 'Telefon raqamini kiriting'
  },
  registration_common_email_input_label: {
    ru: 'E-mail',
    uz: 'E-mail'
  },
  registration_common_email_input_placeholder: {
    ru: 'Адрес электронной почты',
    uz: 'Elektron pochta manzili'
  },

  // REG TIN
  registration_tin_description: {
    ru: 'Укажите ИНН предприятия',
    uz: 'Korxonangizning STIRini kiriting'
  },
  registration_tin_input_label: {
    ru: 'ИНН',
    uz: 'STIR'
  },
  registration_tin_input_placeholder: {
    ru: 'Укажите ИНН',
    uz: 'STIRni kiriting'
  },
  registration_tin_validating: {
    ru: 'Идет проверка ИНН',
    uz: 'STIR tekshirilyabti'
  },
  registration_tin_error_description: {
    ru: 'Обратитесь в нашу службу поддержки по номеру <span>%(phone)s</span>',
    uz: 'Bizning qo\'llab-quvvatlash xizmatimizga <span>%(phone)s</span> raqamiga murojaat qiling'
  },
  registration_by_token_error_description: {
    ru: 'Ошибка при получении данных о пользователе',
    uz: 'Ошибка при получении данных о пользователе'
  },
  loading_registration: {
    ru: 'Идёт регистрация ...',
    uz: 'Идёт регистрация ...'
  },

  // REG PERSONAL
  registration_personal_description: {
    ru: 'Укажите личные данные',
    uz: 'Shaxsiy ma\'lumotlaringizni kiriting'
  },
  registration_personal_input_label: {
    ru: 'Ваше Ф.И.О.',
    uz: 'Sizning F.I.Sh.'
  },
  registration_personal_input_placeholder: {
    ru: 'Укажите Ваше Ф.И.О.',
    uz: 'F.I.Sh.ni kiriting'
  },

  // REG COMPANY
  registration_company_title: {
    ru: 'Информация о предприятии',
    uz: 'Korxona haqida ma\'lumotlar'
  },
  registration_company_description: {
    ru: 'Укажите данные предприятия',
    uz: 'Korxonangiz ma\'lumotlarini kiriting'
  },
  registration_company_ownership_input_label: {
    ru: 'Форма собственности',
    uz: 'Mulkiy shakli'
  },
  registration_company_name_input_label: {
    ru: 'Наименование предприятия',
    uz: 'Korxona nomi'
  },
  registration_company_name_input_placeholder: {
    ru: 'Укажите наименование',
    uz: 'Korxona nomini kiriting'
  },
  registration_company_oked_input_label: {
    ru: 'ОКЭД',
    uz: 'IFUT'
  },
  registration_company_business_activity_input_label: {
    ru: 'КВЭД',
    uz: 'IFUT'
  },
  registration_company_business_type_input_label: {
    ru: 'Категория предприятия',
    uz: 'Korxona toifasi'
  },
  registration_company_created_date_label: {
    ru: 'Дата создания',
    uz: 'Tashkil etilgan sana'
  },
  registration_company_created_date_day: {
    ru: 'День',
    uz: 'Kun'
  },
  registration_company_created_date_month: {
    ru: 'Месяц',
    uz: 'Oy'
  },
  registration_company_created_date_year: {
    ru: 'Год',
    uz: 'Yil'
  },

  // REG CONTACTS
  registration_contacts_title: {
    ru: 'Контактные данные',
    uz: 'Aloqa uchun ma\'lumotlar'
  },
  registration_contacts_description: {
    ru: 'Укажите адрес, контакты и др. реквизиты предприятия',
    uz: 'Korxonangizning manzili, aloqa uchun ma\'lumotlar va boshq. kiriting'
  },
  registration_contacts_website_input_label: {
    ru: 'Web-сайт предприятия',
    uz: 'Web-sayt'
  },
  registration_contacts_website_input_placeholder: {
    ru: 'Укажите web-сайт',
    uz: 'Web-saytni kiriting'
  },
  registration_contacts_email_repeat_label: {
    ru: 'Повторно укажите адрес электронной почты',
    uz: 'Elektron manzilli qaytattan yozing'
  },
  registration_contacts_legal_address_label: {
    ru: 'Юридический адрес',
    uz: 'Yuridik manzil'
  },
  registration_contacts_physical_address_label: {
    ru: 'Фактический адрес',
    uz: 'Amaldagi manzil'
  },
  registration_contacts_address_region: {
    ru: 'Область',
    uz: 'Viloyat'
  },
  registration_contacts_address_district: {
    ru: 'Район',
    uz: 'Tuman'
  },
  registration_contacts_address_street: {
    ru: 'Улица',
    uz: 'Ko\'cha'
  },
  registration_contacts_address_house: {
    ru: 'Номер дома',
    uz: 'Uy raqami'
  },
  registration_contacts_address_postcode: {
    ru: 'Почтовый индекс',
    uz: 'Pochta indeksi'
  },
  registration_contacts_legal_is_physical: {
    ru: 'Является фактическим адресом',
    uz: 'Amaldagi manzil hisoblanadi'
  },

  // REG STAFF
  registration_staff_title: {
    ru: 'Данные о сотрудниках',
    uz: 'Xodimlarning ma\'lumotlari'
  },
  registration_staff_description: {
    ru: 'Укажите сотрудников компании и др. реквизиты предприятия',
    uz: 'Korxona xodimlarini va boshqa rekvizitlarni kiriting'
  },
  registration_staff_common_position: {
    ru: 'Должность',
    uz: 'Lavozimi'
  },
  registration_staff_common_phone: {
    ru: 'Тел. номер',
    uz: 'Tel. raqam'
  },
  registration_staff_common_email: {
    ru: 'Email (необязательно)',
    uz: 'Email (shart emas)'
  },
  registration_staff_director_name_input_label: {
    ru: 'Руководитель',
    uz: 'Rahbar'
  },
  registration_staff_director_name_input_placeholder: {
    ru: 'Укажите Ф.И.О. руководителя',
    uz: 'Rahbarning F.I.Sh.'
  },
  registration_staff_director_position_input_placeholder: {
    ru: 'Укажите должность руководителя',
    uz: 'Rahbarning lavozimini kiriting'
  },
  registration_staff_accountant_name_input_label: {
    ru: 'Бухгалтер',
    uz: 'Hisobchi'
  },
  registration_staff_accountant_name_input_placeholder: {
    ru: 'Укажите Ф.И.О. бухгалтера',
    uz: 'Hisobchining F.I.Sh.'
  },
  registration_staff_responsible_name_input_label: {
    ru: 'Ответственный по работе с GS1 Uzbekistan',
    uz: 'GS1 Uzbekistan bilan ishlash uchun mas\'ul shaxs'
  },
  registration_staff_responsible_name_input_placeholder: {
    ru: 'Укажите Ф.И.О. ответственного',
    uz: 'Mas\'ul shaxsning F.I.Sh.'
  },
  registration_staff_responsible_position_input_placeholder: {
    ru: 'Укажите должность ответственного',
    uz: 'Mas\'ul shaxsning lavozimi'
  },

  // REG BANK
  registration_bank_title: {
    ru: 'Банковские реквизиты',
    uz: 'Bank rekvizitlari'
  },
  registration_bank_description: {
    ru: 'Укажите банковские реквизиты предприятия',
    uz: 'Korxonaning bank rekvizitlarini kiriting'
  },
  registration_bank_code_input_label: {
    ru: 'Код банка',
    uz: 'Bank kodi'
  },
  registration_bank_code_input_placeholder: {
    ru: 'Введите код банка',
    uz: 'Bank kodini kiriting'
  },
  registration_bank_name_input_label: {
    ru: 'Наименование банка',
    uz: 'Bank nomi'
  },
  registration_bank_name_loading_label: {
    ru: 'Загрузка банка',
    uz: 'Bank yuklanmoqda'
  },
  registration_bank_name_input_placeholder: {
    ru: 'Укажите код для отображения наименования',
    uz: 'Bank nomi ko\'rsatilishi uchun kodni kiriting'
  },
  registration_bank_name_error_input_placeholder: {
    ru: 'Банк не найден, проверьте код банка',
    uz: 'Bank topilmadi, bank kodini tekshiring'
  },
  registration_bank_account_code_input_label: {
    ru: 'Расчетный счет',
    uz: 'Hisob raqami'
  },
  registration_bank_account_code_input_placeholder: {
    ru: 'Введите расчетный счет',
    uz: 'Hisob raqamni kiriting'
  },

  // REG QUESTIONS
  registration_questions_title: {
    ru: 'Дополнительные вопросы',
    uz: 'Qo\'shimcha savollar'
  },
  registration_questions_description: {
    ru: 'Ответьте на несколько вопросов',
    uz: 'Bir qancha savollarga javob bering'
  },
  registration_questions_loading_label: {
    ru: 'Загрузка вопросов...',
    uz: 'Savollar yuklanmoqda...'
  },

  // REG PREVIEW
  registration_preview_title: {
    ru: 'Введенные данные',
    uz: 'Kiritilgan ma\'lumotlar'
  },
  registration_preview_description: {
    ru: 'Проверьте свои данные и подтвердите',
    uz: 'Ma\'lumotlaringizni tekshiring va tasdiqlang'
  },
  registration_preview_tin: {
    ru: 'ИНН предприятия',
    uz: 'Korxonaning STIR'
  },
  registration_preview_company_phone: {
    ru: 'Основной номер для контактов',
    uz: 'Aloqa uchun asosiy tel. raqam'
  },
  registration_preview_company_email: {
    ru: 'Эл. почта компании',
    uz: 'Korxona elektron manzili'
  },
  registration_preview_company_website: {
    ru: 'Web-сайт предприятия',
    uz: 'Korxonaning web-sayti'
  },
  registration_button_check_inn: {
    ru: 'Проверить ИНН',
    uz: 'STIRni tekshirish'
  },
  registration_inn_validation_error_message: {
    ru: 'Выбранный ИНН не совпадает с указанным ИНН!',
    uz: 'Tanlangan STIR kiritilgan STIR bilan tog\'ri kelmagan'
  },

  docs_accept_terms_label: {
    ru: 'Принять условия',
    uz: 'Shartlarni qabul qilish'
  },
  docs_sign_and_continue: {
    ru: 'Подписать и продолжить',
    uz: 'Imzolash va davom etish'
  },

  thanks_heading: {
    ru: 'Уважаемый представитель предприятия!',
    uz: 'Hurmatli korxonaning vakili!'
  },
  thanks_text_1: {
    ru: 'Ваши данные были отправлены на проверку ответственному сотруднику Ассоциации.',
    uz: 'Sizning ma\'lumotlaringiz Uyushma mas\'ul xodimiga tekshirish uchun yuborildi.'
  },
  thanks_text_2: {
    ru: 'После проверки Ваших данных на Вашу почту будут отправлены подтверждения и счет на оплату взносов.',
    uz: 'Sizning ma\'lumotlaringiz tekshirilgandan so\'ng, elektron manzilingizga a\'zolikni tasdiqlash va to\'lov uchun hisob raqam yuboriladi.'
  },
  thanks_text_3: {
    ru: 'Спасибо за сотрудничество!',
    uz: 'Hamkorlik uchun rahmat'
  },
  thanks_text_4: {
    ru: 'Для дополнительных вопросов просим Вас обращаться по телефону %(phone)s',
    uz: 'Qo\'shimcha ma\'lumotlar uchun quyidagi raqamga murojaat qilishingiz mumkin: %(phone)s'
  },
}
