import React, { Fragment } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import sectionPropTypes from './sectionPropTypes'
import Section from './Section'

import { EMAIL_PATTERN_VALIDATE } from 'constants/fieldPatterns'
import { useTranslate } from 'utils/translate'
import Title from 'components/Title'
import { FieldWrapFlex, FieldWrap, Input, PhoneInput } from 'components/FormComponents'
import { Divider } from 'components/UI'

const CustomFieldWrap = styled(FieldWrap)`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`

const RegStaff = (props) => {
  const { id, title, description } = props

  const { t } = useTranslate()
  const { register, control } = useFormContext()

  return (
    <Section id={id}>
      <Title description={t(description)}>
        {t(title)}
      </Title>

      <Fragment>
        <CustomFieldWrap>
          <Input
            ref={register({ required: true })}
            name={'staff.head.name'}
            label={t('registration_staff_director_name_input_label')}
            placeholder={t('registration_staff_director_name_input_placeholder')}
          />
        </CustomFieldWrap>
        <CustomFieldWrap>
          <Input
            ref={register({ required: true })}
            name={'staff.head.position'}
            label={t('registration_staff_common_position')}
            placeholder={t('registration_staff_director_position_input_placeholder')}
          />
        </CustomFieldWrap>
        <FieldWrapFlex>
          <FieldWrap>
            <PhoneInput
              name={'staff.head.phone'}
              control={control}
              rules={{ required: true }}
              placeholder={t('registration_staff_common_phone')}
            />
          </FieldWrap>
          <FieldWrap>
            <Input
              ref={register({ pattern: EMAIL_PATTERN_VALIDATE })}
              name={'staff.head.email'}
              placeholder={t('registration_staff_common_email')}
            />
          </FieldWrap>
        </FieldWrapFlex>
      </Fragment>

      <Divider />

      <Fragment>
        <CustomFieldWrap>
          <Input
            ref={register()}
            name={'staff.accountant.name'}
            label={t('registration_staff_accountant_name_input_label')}
            placeholder={t('registration_staff_accountant_name_input_placeholder')}
          />
        </CustomFieldWrap>
        <FieldWrapFlex>
          <FieldWrap>
            <PhoneInput
              name={'staff.accountant.phone'}
              control={control}
              placeholder={t('registration_staff_common_phone')}
            />
          </FieldWrap>
          <FieldWrap>
            <Input
              ref={register({ pattern: EMAIL_PATTERN_VALIDATE })}
              name={'staff.accountant.email'}
              placeholder={t('registration_staff_common_email')}
            />
          </FieldWrap>
        </FieldWrapFlex>
      </Fragment>

      <Divider />

      <Fragment>
        <CustomFieldWrap style={{ pointerEvents: 'none' }}>
          <Input
            ref={register({ required: true })}
            name={'staff.personInCharge.name'}
            label={t('registration_staff_responsible_name_input_label')}
            placeholder={t('registration_staff_responsible_name_input_placeholder')}
          />
        </CustomFieldWrap>
        <CustomFieldWrap>
          <Input
            ref={register({ required: true })}
            name={'staff.personInCharge.position'}
            label={t('registration_staff_common_position')}
            placeholder={t('registration_staff_responsible_position_input_placeholder')}
          />
        </CustomFieldWrap>
        <FieldWrapFlex>
          <FieldWrap style={{ pointerEvents: 'none' }}>
            <PhoneInput
              name={'staff.personInCharge.phone'}
              control={control}
              rules={{ required: true }}
              placeholder={t('registration_staff_common_phone')}
            />
          </FieldWrap>
          <FieldWrap>
            <Input
              ref={register({ pattern: EMAIL_PATTERN_VALIDATE })}
              name={'staff.personInCharge.email'}
              placeholder={t('registration_staff_common_email')}
            />
          </FieldWrap>
        </FieldWrapFlex>
      </Fragment>
    </Section>
  )
}

RegStaff.propTypes = sectionPropTypes

export default RegStaff
