import {
  concat,
  filter,
  find,
  flatten,
  fromPairs,
  groupBy,
  map,
  omit,
  path,
  pipe,
  prop,
  propOr,
  toPairs,
  uniq
} from 'ramda'
import toSnakeCase from 'utils/toSnakeCase'
import toCamelCase from 'utils/toCamelCase'
import { phoneNumberParse } from 'components/FormComponents/Input/PhoneInput'

export const transformServerDataToClient = values => {
  const simpleValues = omit([
    'bankCode',
    'answers',
    'signatures',
    'contract',
    'staffs',
    'addresses'
  ], values)

  const address = {
    ...pipe(
      prop('addresses'),
      map(({ addressType, ...addressData }) => {
        return [addressType, addressData]
      }),
      fromPairs,
      toCamelCase
    )(values)
  } /* {legal, actual} */

  const answers = pipe(
    prop('answers'),
    groupBy(prop('question')),
    toPairs,
    map(item => {
      const [questionId, answers] = item
      const questionKey = `question_${questionId}`
      const answersIds = map(prop('id'), answers)
      return [questionKey, answersIds]
    }),
    fromPairs
  )(values)

  const staff = {
    ...pipe(
      prop('staffs'),
      map(({ staffType, phone, ...staffData }) => {
        return [
          staffType,
          {
            phone: phoneNumberParse(phone),
            ...staffData
          },
        ]
      }),
      fromPairs,
      toCamelCase
    )(values)
  } /* {head, personInCharge, accountant} */

  const businessType = path(['contract', 'businessType'], values)
  const bankCode = path(['bankCode', 'code'], values)
  const bankName = path(['bankCode', 'name'], values)
  const contractNumber = path(['contract', 'contractNumber'], values)

  return {
    ...simpleValues,
    address,
    answers,
    bankCode,
    bankName,
    businessType,
    contractNumber,
    staff
  }
}

export const transformClientDataToServer = values => {
  const answers = pipe(
    propOr({}, 'answers'),
    toPairs,
    map(([, values]) => values),
    filter(Boolean),
    flatten,
    uniq
  )(values)

  const allAddresses = pipe(
    prop('address'),
    toSnakeCase,
    toPairs,
    map(([addressType, addressData]) => ({
      ...addressData,
      addressType,
      region: path(['region', 'id'], addressData),
      district: path(['district', 'id'], addressData)
    })),
    filter(prop('postcode'))
  )(values)
  const hasActualAddress = !!path(['address', 'actual'], values)
  const legalAddress = find(({ addressType }) => addressType === 'legal', allAddresses)
  const actualAddress = {
    ...legalAddress,
    addressType: 'actual'
  }

  const addresses = hasActualAddress ? allAddresses : concat(allAddresses, [actualAddress])

  const staffs = pipe(
    prop('staff'),
    toSnakeCase,
    toPairs,
    map(([staffType, staffData]) => ({
      staffType,
      ...staffData
    })),
    filter(prop('name'))
  )(values)

  return toSnakeCase({
    tin: prop('tin', values),
    name: prop('name', values),
    ownership: path(['ownership', 'id'], values),
    ecoActivity: path(['ecoActivity', 'id'], values),
    businessType: path(['businessType', 'id'], values),

    phoneNumber: phoneNumberParse(prop('phoneNumber', values)),
    webSite: prop('webSite', values),
    email: prop('email', values),
    addresses,
    staffs,

    bankCode: prop('bankCode', values),
    checkingAccount: prop('checkingAccount', values),
    cardSign: prop('key', values),
    validTo: prop('validTo', values),
    serialNumber: prop('serialNumber', values),
    answers
  })
}

export const formatContractNumber = value => {
  if (!value) return ''

  const stringValue = value.toString()
  const contractNumber = stringValue.substring(0, stringValue.length - 2)
  const contractYear = stringValue.substring(stringValue.length - 2)

  return `${contractNumber}/${contractYear}`
}
