import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { pipe, prop } from 'ramda'
import styled from 'styled-components'

import RegistrationBox from './RegistrationBox'

import * as API from 'constants/api'
import * as PATTERNS from 'constants/fieldPatterns'
import { validateLength } from 'utils/validations'
import { transformServerDataToClient } from 'utils/transformData'
import { useTranslate } from 'utils/translate'
import { useCreate } from 'hooks'
import { useReg } from 'contexts/RegistrationProvider'
import Title from 'components/Title'
import { FieldWrap, Form, Input } from 'components/FormComponents'
import { CancelButton, PrimaryButton } from 'components/Buttons'

const ErrorDesc = styled.div`
  font-size: 1.25em;
  margin-bottom: 50px;
  & span {
    color: ${props => props.theme.palette.primary.default};
  }
`

const RegTIN = props => {
  const { toNextStep } = props

  const { t } = useTranslate()

  const [error, setError] = useState(null)
  const { state, dispatch } = useReg()
  const checkTin = useCreate(API.CHECK_TIN)

  const { isLoading } = checkTin

  const goBack = () => setError(null)

  const onSubmit = values => {
    checkTin.create(values)
      .then(data => {
        if (data.id) {
          const transformedData = transformServerDataToClient(data)
          dispatch(transformedData)
        } else dispatch(values)
        toNextStep()
      })
      .catch(pipe(
        prop('tin'),
        setError
      ))
  }

  if (error) {
    const errorDesc = t('registration_tin_error_description', {
      phone: process.env.REACT_APP_GS1_PHONE_NUMBER
    })
    return (
      <RegistrationBox>
        <Title>{error}</Title>
        <ErrorDesc
          dangerouslySetInnerHTML={{ __html: errorDesc }}
        />
        <CancelButton onClick={goBack}>
          {t('common_button_back')}
        </CancelButton>
      </RegistrationBox>
    )
  }

  return (
    <RegistrationBox>
      <Title description={t('registration_tin_description')}>
        {t('registration_title')}
      </Title>

      <Form mode={'onBlur'} onSubmit={onSubmit} defaultValues={state}>
        {({ register, watch }) => {
          const inn = watch('tin')
          const isButtonDisabled = !inn || isLoading

          return (
            <Fragment>
              <FieldWrap>
                <Input
                  name={'tin'}
                  label={t('registration_tin_input_label')}
                  placeholder={t('registration_tin_input_placeholder')}
                  maxLength={9}
                  ref={register({
                    required: true,
                    pattern: PATTERNS.NUMBER_PATTERN_VALIDATE,
                    validate: validateLength(9)
                  })}
                />
              </FieldWrap>
              <PrimaryButton fullWidth={true} disabled={isButtonDisabled}>
                {isLoading
                  ? t('registration_tin_validating')
                  : t('common_button_next')
                }
              </PrimaryButton>
            </Fragment>
          )
        }}
      </Form>
    </RegistrationBox>
  )
}

RegTIN.propTypes = {
  toNextStep: PropTypes.func.isRequired,
}

export default RegTIN
