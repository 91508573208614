import styled from 'styled-components'

const getBackgroundColor = props => props.error
  ? props.theme.palette.input.backgroundError
  : props.theme.palette.input.background

const getBackgroundColorHover = props => props.error
  ? props.theme.palette.input.backgroundError
  : props.theme.palette.input.backgroundHover

const getBorderColorFocus = props => props.error
  ? props.theme.palette.error
  : props.theme.palette.primary.default

export default styled.input`
  background-color: ${getBackgroundColor};
  border-radius: ${props => props.theme.shape.borderRadius};
  border: 1px solid transparent;
  height: ${props => props.theme.shape.input.height};
  padding: ${props => props.theme.shape.input.padding};
  outline: none;
  transition: ${props => props.theme.transition};
  width: 100%;

  ::placeholder {
    color: ${props => props.theme.palette.input.placeholder};
  }
  ::-ms-input-placeholder {
    color: ${props => props.theme.palette.input.placeholder};
  }
  
  &:disabled {
    pointer-events: none;
    opacity: 0.7;
  }
  
  &:read-only {
    pointer-events: none;
  }

  &:not(:read-only):hover {
    background-color: ${getBackgroundColorHover};
  }
  
  &:not(:read-only):focus {
    background-color: ${props => props.theme.palette.common.white};
    border-color: ${getBorderColorFocus};
  }
`
