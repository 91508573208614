import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'utils/translate'
import { useAdmissionContract } from 'contexts/AdmissinContractProvider'
import { useCreate } from 'hooks'
import { transformServerDataToClient } from 'utils/transformData'
import { pipe, prop } from 'ramda'
import Loader from 'components/Loader/Loader'
import Error from 'components/Error/Error'
import * as API from 'constants/api'
import useQuery from 'hooks/useQuery'

const AdmissinContractTIN = props => {
  const { toNextStep } = props

  const { t } = useTranslate()

  const query = useQuery()

  const { dispatch } = useAdmissionContract()
  const tin = query.get('tin')

  const checkTin = useCreate(API.CHECK_TIN)
  const { isFail } = checkTin

  useEffect(() => {
    checkTin.create({ tin })
      .then(data => {
        const transformedData = transformServerDataToClient(data)
        dispatch(transformedData)
        toNextStep()
      })
      .catch(pipe(
        prop('tin')
      ))
  }, [])

  if (isFail) {
    return (
      <Error
        title={t('registration_by_token_error_description')}
      />
    )
  }

  return (
    <Loader
      text={t('loading_registration')}
    />
  )
}

AdmissinContractTIN.propTypes = {
  toNextStep: PropTypes.func.isRequired
}

export default AdmissinContractTIN
