import React from 'react'
import PropTypes from 'prop-types'
import AuthWrapper from 'components/AuthWrapper'

const AppLayout = ({ children }) => {
  return (
    <AuthWrapper>
      {children}
    </AuthWrapper>
  )
}

AppLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default AppLayout
