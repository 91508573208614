/* eslint-disable max-len */
import React from 'react'
import { SVG } from 'components/Utils'

export default props => (
  <SVG {...props}>
    <g>
      <path
        d="M12,1C5.9,1,1,5.9,1,12c0,6.1,4.9,11,11,11s11-4.9,11-11C23,5.9,18.1,1,12,1z M12,21c-5,0-9-4-9-9c0-5,4-9,9-9c5,0,9,4,9,9 C21,17,17,21,12,21z" />
      <path d="M12,7c-0.6,0-1,0.4-1,1v4c0,0.6,0.4,1,1,1s1-0.4,1-1V8C13,7.4,12.6,7,12,7z" />
      <path d="M12,15c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1s1-0.4,1-1C13,15.4,12.6,15,12,15z" />
    </g>
  </SVG>
)
