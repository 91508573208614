import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Label = styled.div`
  color: ${props => props.error ? props.theme.palette.error : props.theme.palette.input.label};
  display: ${props => props.children ? 'block' : 'none'};
  font-weight: 500;
  margin-bottom: 12px;
  transition: ${props => props.theme.transition};
`

const Hint = styled.span`
  color: ${props => props.theme.palette.input.placeholder};
  margin-left: 4px;
`

const InputLabel = props => {
  const { children, hint, ...restProps } = props

  return (
    <Label {...restProps}>
      {children}
      {hint && (
        <Hint>({hint})</Hint>
      )}
    </Label>
  )
}

InputLabel.propTypes = {
  children: PropTypes.string,
  hint: PropTypes.string,
  error: PropTypes.any
}

export default InputLabel
