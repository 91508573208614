import styled from 'styled-components'

export default styled.section`
  border-bottom: 1px solid ${props => props.theme.palette.toggle.shapeColor};
  padding: 60px 0;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border-bottom: none;
    padding-bottom: 40px;
  }
`
