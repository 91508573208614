import React, { useState, useEffect, forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { FieldError, FieldLabel as FieldLabelBase } from '../Utils'
import { SwitchContainer } from 'components/Switches'
import { useFieldError } from 'hooks'

const Group = styled.div``

const Checkboxes = styled.div`
  ${props => props.mode === 'block' && (
    css`
      display: grid;
      grid-gap: 15px;

      & ${SwitchContainer} {
        margin-bottom: unset;
        position: relative;
        width: 100%;
        z-index: 0;

        &:after {
          content: '';
          position: absolute;
          transition: ${props => props.theme.transition};
          top: -8px;
          left: -26px;
          right: 0;
          bottom: -8px;
          z-index: -1;
        }

        &:not([data-checked="true"]):hover:after {
          background-color: ${props => props.theme.palette.toggle.hoverBackground};
          border-radius: ${props => props.theme.shape.borderRadius};
        }

        &[data-checked="true"]:after {
          background-color: ${props => props.theme.palette.toggle.activeBackground};
        }

        &[data-checked-position="start"]:after {
          border-top-left-radius: ${props => props.theme.shape.borderRadius};
          border-top-right-radius: ${props => props.theme.shape.borderRadius};
        }

        &[data-checked-position="solo"]:after {
          border-radius: ${props => props.theme.shape.borderRadius};
        }

        &[data-checked-position="end"]:after {
          border-bottom-left-radius: ${props => props.theme.shape.borderRadius};
          border-bottom-right-radius: ${props => props.theme.shape.borderRadius};
        }
      }
    `
  )}
  ${props => props.mode === 'inline' && (
    css`
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -12px;

      & ${SwitchContainer} {
        margin-right: 25px;

        &:last-child {
          margin-bottom: 12px;
        }
      }
    `
  )}
`
const FieldLabel = styled(FieldLabelBase)`
  margin-bottom: 25px;
`

const CheckboxGroup = forwardRef((props, ref) => {
  const { value, children, label, onChange, mode, ...rest } = props
  const { name } = rest
  const [checkedValues, setCheckedValues] = useState(value)

  const error = useFieldError(name)

  useEffect(() => {
    setCheckedValues(value)
    if (typeof onChange === 'function') {
      onChange(value)
    }
  }, [value, onChange])

  const onChangeItem = (value, isChecked) => {
    const formedValues = isChecked
      ? [...checkedValues, value]
      : checkedValues.filter(item => item !== value)
    setCheckedValues(formedValues)
    if (typeof onChange === 'function') {
      onChange(formedValues)
    }
  }

  const checkboxes = React.Children.map(children, (child, key) => {
    const checkboxProps = child.props
    const childValue = checkboxProps.value

    return React.cloneElement(child, {
      key,
      ...checkboxProps,
      'data-checked': checkedValues.includes(childValue),
      checked: checkedValues.includes(childValue),
      onChange: isChecked => onChangeItem(childValue, isChecked)
    })
  })

  const getActiveCheckboxPosition = index => {
    const prevEl = checkboxes[index - 1]
    const currentEl = checkboxes[index]
    const nextEl = checkboxes[index + 1]
    const isActivePrevEl = prevEl && prevEl.props.checked
    const isActiveCurrentEl = currentEl.props.checked
    const isActiveNextEl = nextEl && nextEl.props.checked

    if (!isActivePrevEl && isActiveCurrentEl && !isActiveNextEl) {
      return 'solo'
    }

    if (!isActivePrevEl && isActiveCurrentEl) {
      return 'start'
    } else if (isActivePrevEl && isActiveCurrentEl && isActiveNextEl) {
      return 'mid'
    } else if (isActivePrevEl && isActiveCurrentEl) {
      return 'end'
    }

    return null
  }

  const formedCheckboxes = mode === 'block'
    ? React.Children.map(checkboxes, (item, key) => {
      const activePosition = getActiveCheckboxPosition(key)
      return React.cloneElement(item, {
        ...item.props,
        'data-checked-position': activePosition
      })
    })
    : checkboxes

  return (
    <Group ref={ref} {...rest}>
      <FieldLabel error={error}>{label}</FieldLabel>
      <Checkboxes error={error} mode={mode}>
        {formedCheckboxes}
      </Checkboxes>
      <FieldError>{error}</FieldError>
    </Group>
  )
})

CheckboxGroup.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  mode: PropTypes.oneOf(['inline', 'block'])
}

CheckboxGroup.defaultProps = {
  mode: 'block',
  value: []
}

export default CheckboxGroup
