import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { useTranslate } from 'utils/translate'
import { Divider } from 'components/UI'
import { Checkbox } from 'components/FormComponents'
import { SecondaryButton } from 'components/Buttons'

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`

const Button = props => {
  const { onSign } = props

  const { t } = useTranslate()
  const [agreed, setAgreed] = useState(false)

  return (
    <Wrapper>
      <Divider />

      <Checkbox
        label={t('docs_accept_terms_label')}
        checked={agreed}
        onChange={setAgreed}
      />

      {agreed && (
        <SecondaryButton onClick={onSign}>
          {t('docs_sign_and_continue')}
        </SecondaryButton>
      )}
    </Wrapper>
  )
}

Button.propTypes = {
  onSign: PropTypes.func.isRequired
}

export default Button
