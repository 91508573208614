import React from 'react'
import styled from 'styled-components'
import { Text } from 'components/UI'

const TableFooter = styled.div`
  margin-top: 15px;
  & span {
    color: ${props => props.theme.palette.primary.default};
  }
`

export default () => {
  return (
    <TableFooter>
      <Text align={'center'} fontWeight={500} transform={'uppercase'}>
        Murojaat uchun telefon / Телефон для справок: <span>{process.env.REACT_APP_GS1_PHONE_NUMBER_CONTRACT}</span>
      </Text>
    </TableFooter>
  )
}
