import styled from 'styled-components'
import buttonPropTypes from './buttonPropTypes'
import BaseButton from './BaseButton'

const SecondaryButton = styled(BaseButton)`
  background: ${props => props.theme.palette.secondary.default};
  color: ${props => props.theme.palette.common.white};

  &:focus {
    background: ${props => props.theme.palette.secondary.lighter};
  }

  &:hover {
    background: ${props => props.theme.palette.secondary.darker};
  }

  &:active {
    background: ${props => props.theme.palette.secondary.default};
  }
`

SecondaryButton.propTypes = buttonPropTypes

export default SecondaryButton
