import React, { createContext, useReducer, useContext } from 'react'
import PropTypes from 'prop-types'
// import values from 'routes/registration/components/RegPreview/values'

const RegistrationContext = createContext({})

const phoneNumberDefault = '+998 '

const initialState = {
  name: '',
  staff: {
    head: { phone: phoneNumberDefault },
    accountant: { phone: phoneNumberDefault },
    personInCharge: { phone: phoneNumberDefault }
  },
  phoneNumber: phoneNumberDefault,
  // ...values
}

const registrationReducer = (state, action) => ({ ...state, ...action })

export const RegistrationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(registrationReducer, initialState)

  return (
    <RegistrationContext.Provider value={{ state, dispatch }}>
      {children}
    </RegistrationContext.Provider>
  )
}

RegistrationProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const useReg = () => useContext(RegistrationContext)

export { RegistrationContext }
