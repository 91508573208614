/* eslint-disable indent */
import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import {
  SwitchContainer,
  SwitchInput,
  CheckMark
} from 'components/Switches'

const StyledCheckMark = styled(CheckMark)`
  ${props => props.indeterminate && (
    css`
      border-color: ${props => props.theme.palette.primary.default};
    `
  )}
  &:after {
    border-style: solid;
    border-color: ${props => props.theme.palette.common.white};
    border-width: 0 2px 2px 0;
    height: 10px;
    transform: rotate(45deg) scale(0);
    left: 8px;
    top: 4px;
    width: 4px;
    ${props => props.indeterminate && (
      css`
        background: ${props => props.theme.palette.primary.default};
        border: none;
        opacity: 1;
        transform: scale(1);
        left: 4px;
        top: 7px;
        height: 2px;
        width: 8px;
      `
  )}
  }
`

const StyledInput = styled(SwitchInput)`
  &:checked + ${StyledCheckMark}:after {
    transform: rotate(45deg) scale(1);
  }
`

const Checkbox = props => {
  const { onChange, ...restProps } = props
  const { label, disabled, checked, indeterminate } = restProps

  const onChecked = ev => onChange && onChange(ev.target.checked, ev)

  return (
    <SwitchContainer disabled={disabled} label={label} {...restProps}>
      {label}
      <StyledInput checked={checked} onChange={onChecked} type={'checkbox'} />
      <StyledCheckMark indeterminate={indeterminate} />
    </SwitchContainer>
  )
}

Checkbox.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
  onChange: PropTypes.func,
  checked: PropTypes.bool
}

export default Checkbox
